import { Section } from '@sweb-front/components';
import { usePageRedirection } from '@sweb-front/hooks';
import { selectDisplays, useAppSelector } from '@sweb-front/store';
import { ascSort } from '@sweb-front/utils';
import {
  FORM_FIELDS_MAPPING,
  INFORMATIONPAGE,
  INFORMATIONS,
} from '@vat/configuration';
import { InformationForm, InformationFormWestCoast } from '@vat/containers';
import { useAppOptions } from '@vat/utils';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  BannerWrapper,
  CustomedBubbleInfoWrapper,
  SectionContainer,
} from './styles';

interface InformationProps {
  isWestCoast?: boolean;
}

const Information = ({ isWestCoast }: InformationProps) => {
  const { t } = useTranslation();
  const displays = useAppSelector(selectDisplays);

  const kycFields = Object.keys(FORM_FIELDS_MAPPING.KYC).sort((k, l) =>
    ascSort(FORM_FIELDS_MAPPING.KYC[k], FORM_FIELDS_MAPPING.KYC[l])
  );
  const { isPageLoading } = usePageRedirection(INFORMATIONS);
  const [showBubbleInfo, setShowBubbleInfo] = useState(false);
  const [bubbleInfoHiddenByAction, setBubbleInfoHiddenByAction] =
    useState(false);

  const [getInformationFields] = (displays ?? [])
    .filter((d) => d.displayCd === (isWestCoast ? 'KYCONLINE' : 'KYC'))
    .map((df) => df.fields.split(','));

  const fields = kycFields.filter((f) =>
    (getInformationFields ?? []).includes(f)
  );

  const { setAppOptions } = useAppOptions();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBubbleInfo(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    setAppOptions({
      isMounted: !isPageLoading,
    });
  }, [isPageLoading]);

  return (
    !isPageLoading && (
      <SectionContainer id="section-container">
        {fields.includes(INFORMATIONPAGE.MKYCIDEN) && (
          <BannerWrapper
            className={
              showBubbleInfo
                ? 'open'
                : `close ${bubbleInfoHiddenByAction ? 'clear-component' : ''}`
            }
          >
            <CustomedBubbleInfoWrapper
              id={`modal-kyc-cin`}
              description={
                <Trans
                  i18nKey="basicInformations.bubbleInfo.kyc"
                  components={{
                    strong: <span>{t('basicInformations.name')}</span>,
                  }}
                />
              }
              onClose={() => {
                setBubbleInfoHiddenByAction(true);
                setShowBubbleInfo((prev) => !prev);
              }}
              hasClosebtn
            />
          </BannerWrapper>
        )}
        <Section id="section" title={t('basicInformations.title')}>
          {isWestCoast ? (
            <InformationFormWestCoast fields={fields} />
          ) : (
            <InformationForm fields={fields} />
          )}
        </Section>
      </SectionContainer>
    )
  );
};

export default Information;
