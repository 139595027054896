import {
  selectSteps,
  setLastAppUrl,
  setWebAEventPage,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { useCallback, useEffect, useMemo } from 'react';
import { useCloseOpportunity } from '@vat/utils';
import { useNavigate, useLocation } from 'react-router-dom';

const useManagingStep = () => {
  const steps = useAppSelector(selectSteps);
  const { closeOpportunity } = useCloseOpportunity();
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const currentStep = useMemo(() => {
    if (steps && steps.length > 0) {
      return (steps ?? []).find((st) => st.paths.includes(location.pathname));
    }
    return undefined;
  }, [steps]);

  useEffect(() => {
    dispatch(setLastAppUrl(currentStep?.paths?.[0] ?? ''));
  }, [steps, currentStep]);

  const previousStep = useMemo(() => {
    if (currentStep && steps.length > 0) {
      return steps.find((st) => st.step === currentStep.step - 1);
    }
    return undefined;
  }, [steps, currentStep]);

  const nextStep = useMemo(() => {
    if (currentStep && steps.length > 0) {
      return (steps ?? []).find((st) => st.step === currentStep.step + 1);
    }
    return undefined;
  }, [steps, currentStep]);

  const nextStepFromCustomStep = (customComponent) => {
    const customStep = steps.find(
      (step) => step?.components == customComponent
    );
    if (customStep && steps.length > 0) {
      return (steps ?? []).find((st) => st.step === customStep.step + 1);
    }
    return undefined;
  };

  const goToNextStep = useCallback(() => {
    if (nextStep) {
      history(nextStep.paths[0]);
    }
    dispatch(setWebAEventPage('module_interaction'));
  }, [nextStep, steps]);

  const goToNextStepCustomStep = useCallback(
    (customStep) => {
      const nextStep = nextStepFromCustomStep(customStep);
      if (nextStep) {
        history(nextStep.paths[0]);
      }
      dispatch(setWebAEventPage('module_interaction'));
    },
    [nextStep, steps]
  );

  const goToWebMarchand = useCallback(
    (
      siteWebMarchand,
      location: string,
      callback?: () => void,
      callbackOnError?: () => void
    ) => {
      const token = localStorage.getItem('token');
      closeOpportunity(
        token || '',
        'FUNCTIONAL',
        `Abandon de parcours suite au clic "${location}" :: l'utilisateur est revenu sur le site web marchand.`,
        () => {
          if (siteWebMarchand) {
            window.location.href = siteWebMarchand;
            callback?.();
          }
        },
        () => {
          callbackOnError?.();
        }
      );
    },
    [localStorage.getItem('token')]
  );

  const goBackToStep = useCallback(() => {
    // TODO ajouter un paramètre à cette méthode pour les urls de redirections et utiliser
    // le hook useRedirection lorsqu'on sera redirigé vers Monext ou quick sign
    if (previousStep) {
      history(previousStep.paths[0]);
    }
  }, [previousStep, steps]);

  const redirectToErrorPage = useCallback(() => {
    history('/erreur');
  }, []);

  return {
    goToNextStep,
    goToNextStepCustomStep,
    goBackToStep,
    goToWebMarchand,
    redirectToErrorPage,
  };
};

export default useManagingStep;
