import {
  selectNavigation,
  selectTheme,
  useAppSelector,
} from '@sweb-front/store';
import { useCallback, useEffect } from 'react';

const useContractNotFoundErrorPage = () => {
  const appNavigation = useAppSelector(selectNavigation);
  const themeName = useAppSelector(selectTheme);

  const onBackToIdentification = useCallback(() => {}, [
    appNavigation?.routingContexts,
  ]);

  useEffect(() => {
    // The user does not have to now the path of our application
    window.history.replaceState({}, document.title, '/');
  }, []);

  // Display none cookies policy
  if (themeName === 'CETELEM') {
    const cookiesPolicy = document.querySelectorAll('.cookies-policy');
    if (cookiesPolicy?.length) {
      cookiesPolicy[0].classList.add('d-none');
    }
  }

  return {
    onBackToIdentification,
  };
};

export default useContractNotFoundErrorPage;
