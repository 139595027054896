import { isStringEmpty } from '@sweb-front/utils';

import {
  CenterWrapper,
  ContentWrapper,
  ErrorPageWrapper,
  TitleCmp,
} from './styles';
import useErrorPage from './useErrorPage';
import ErrorDeskIcon from 'src/components/Icons/ErrorDeskIcon';

const ErrorPage = () => {
  const {
    getClassName,
    getTitleContent,
    getFirstParagraphContent,
    getSecondParagraphContent,
    getThirdParagraphContent,
  } = useErrorPage();

  return (
    <ErrorPageWrapper data-testid="ErrorPageWrapper">
      <ErrorDeskIcon />
      <CenterWrapper>
        <ContentWrapper>
          <TitleCmp>{getTitleContent()}</TitleCmp>
          <span>{getFirstParagraphContent()}</span>
          {!isStringEmpty(getSecondParagraphContent()) && (
            <span className={getClassName()}>
              {getSecondParagraphContent()}
            </span>
          )}
          {!isStringEmpty(getThirdParagraphContent()) && (
            <span className={getClassName()}>{getThirdParagraphContent()}</span>
          )}
        </ContentWrapper>
      </CenterWrapper>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
