import { axiosInstance } from '@sweb-front/services';
import { ISelectCombo } from '@sweb-front/types';
import { concantenedObjectWithComma, isStringEmpty } from '@sweb-front/utils';
import { capitalize, formatNumber, textRegex } from '@vat/utils';
import { AxiosResponse } from 'axios';
import { GenderInputDS } from '@sweb-front/components';
import { RefObject } from 'react';
import { sha256 } from 'js-sha256';
import { IOpportunityState } from '@sweb-front/store';
import { saveXtn } from '@vat/services';
import { RefinementCtx, z } from 'zod';
import { t } from 'i18next';

export const pad = (s: number) => {
  return s < 10 ? `0${s}` : s;
};

export const getMatchedItem = (
  value: string,
  propertyToMatch: string,
  items: ISelectCombo[]
): ISelectCombo | undefined => {
  if (!value || value === '' || value === null) {
    return undefined;
  }

  return (items ?? []).find(
    (item) =>
      item[propertyToMatch].toUpperCase() === (value ?? '').toUpperCase()
  );
};

export const scrollToFirstInvalidElement = (
  ref?: RefObject<HTMLElement | GenderInputDS>
) => {
  const firstElement = Array.from(ref?.current?.children ?? []).find(
    (element) => element.getAttribute('aria-invalid') === 'true'
  );

  if (firstElement) {
    window.scroll({
      top: window.scrollY + firstElement.getBoundingClientRect().top,
    });
  }
};

export const isAutocompleteInvalid = (
  value: string | undefined,
  record: Record<string, string>
) => {
  return (
    isStringEmpty(value) ||
    (!isStringEmpty(value) &&
      value !==
        concantenedObjectWithComma({
          localite: record?.localite,
          codePostal: record?.codePostal,
          pays: record?.pays,
        }))
  );
};

type IsInvalidType = string | null | undefined;

export const isInvalidInputWithPattern = (value: IsInvalidType) => {
  return isInvalidTextInput(value) || textRegex.test(value);
};

export const isInvalidText = (value: IsInvalidType) => {
  return textRegex.test(value);
};

export const isInvalidTextInput = (value: IsInvalidType) => {
  return (value ?? '')?.trim().length < 2 || (value ?? '')?.trim().length > 30;
};

export const customValidityPattern = (pattern: string, errorMessage: string) =>
  JSON.stringify([
    {
      regexp: pattern,
      errorMessage,
    },
  ]);

export const fetchData = async (
  url: string,
  urlParams: Record<string, unknown>,
  callback?: (args: ISelectCombo[]) => void,
  errorCallback?: (err) => void
) => {
  try {
    const response = await axiosInstance().get(url, urlParams);
    callback?.(response.data);
    return response.data;
  } catch (err) {
    return errorCallback?.(err);
  }
};

export const postData = async (
  url: string,
  params: unknown,
  callback?: (res: AxiosResponse<unknown>) => void,
  errorCallback?: (res: number) => void
) => {
  try {
    const response = await axiosInstance().post(url, params);
    callback?.(response);
    return response;
  } catch (errCode) {
    return errorCallback?.(errCode as number);
  }
};

export const roundValue = (input: string) => {
  let res: string | undefined = input;
  const [entiere, decimal, error] = input.replace(' ', '').split(',');

  if (error) {
    return res;
  }
  if (decimal && parseInt(decimal, 10) < 50 && /^[0-9]{1,2}$/.test(decimal)) {
    res = formatNumber(`${parseInt(entiere, 10)}`);
  }
  if (decimal && parseInt(decimal, 10) >= 50 && /^[0-9]{1,2}$/.test(decimal)) {
    if (['99999', '99 999'].includes(entiere)) {
      res = formatNumber(entiere);
    } else {
      res = formatNumber(`${parseInt(entiere, 10) + 1}`);
    }
  }

  return res;
};

export const isInputValidToPattern = (
  input: IsInvalidType,
  pattern: string
) => {
  const regexp = new RegExp(pattern);
  return regexp.test(input ?? '');
};

export const arrayRange = (start: number, stop: number, step: number) => {
  if (start > stop || start < 0 || stop < 0) {
    return [];
  }
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step
  );
};

export const formatToBnpString = (
  value: string | undefined,
  maxChar: number
) => {
  const regexp = new RegExp("[^a-zA-Zzàâçéèêîôùû'´ëä -]", 'gi');
  const res = (value ?? '').replace(regexp, '');
  return res.substring(0, maxChar);
};

export const sanitizeStringForNumber = (str: string, maxLength?: number) =>
  str.replace(/[^0-9.]/g, '').substring(0, maxLength || undefined);

export const formatNumberWithComma = (nbr?: number) =>
  nbr
    ? nbr.toLocaleString('fr-FR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : 0;

export const dateOf16 = (dateString) => {
  const dob = new Date(dateString);
  return new Date(dob.getFullYear() + 16, dob.getMonth(), dob.getDate());
};

export const setIdHashed = (opportunity: IOpportunityState) => {
  if (!opportunity) {
    return '';
  }

  const stringToHash: string = removeSpecialCharacters(
    replaceAccents(opportunity.opportunityIdExt)
  ).toLowerCase();
  return sha256(stringToHash);
};

export function replaceAccents(string: string) {
  if (string.search(/[\xC0-\xFF]/g) > -1) {
    string = string
      .replace(/[\xC0-\xC5]/g, 'A')
      .replace(/[\xC6]/g, 'AE')
      .replace(/[\xC7]/g, 'C')
      .replace(/[\xC8-\xCB]/g, 'E')
      .replace(/[\xCC-\xCF]/g, 'I')
      .replace(/[\xD0]/g, 'D')
      .replace(/[\xD1]/g, 'N')
      .replace(/[\xD2-\xD6\xD8]/g, 'O')
      .replace(/[\xD9-\xDC]/g, 'U')
      .replace(/[\xDD]/g, 'Y')
      .replace(/[\xE0-\xE5]/g, 'a')
      .replace(/[\xE6]/g, 'ae')
      .replace(/[\xE7]/g, 'c')
      .replace(/[\xE8-\xEB]/g, 'e')
      .replace(/[\xEC-\xEF]/g, 'i')
      .replace(/[\xF0]/g, 'd')
      .replace(/[\xF1]/g, 'n')
      .replace(/[\xF2-\xF6\xF8]/g, 'o')
      .replace(/[\xF9-\xFC]/g, 'u')
      .replace(/[\xFD\xFF]/g, 'y');
  }

  return string;
}

export function removeSpecialCharacters(string: string) {
  return string.replace(/[^0-9a-zA-Z]/g, '');
}

export function scrollToErrorMessage() {
  const firstErrorElement = document.querySelector('#errorMessage');
  const parentElement = firstErrorElement?.parentElement;
  if (parentElement) {
    parentElement.scrollIntoView({ behavior: 'smooth' });
  }
}

export function retrieveAndSaveXtn(opportunity, person, values) {
  const birthDtXtn = `${new Date(
    person?.personalInformation?.birthDt ?? values.birthDt
  ).getFullYear()}${pad(
    new Date(
      person?.personalInformation?.birthDt ?? values.birthDt
    ).getMonth() + 1
  )}${pad(
    new Date(person?.personalInformation?.birthDt ?? values.birthDt).getDate()
  )}`;

  // Here we are sure to get a valid birthDate so we add XTN call for userId to harmonized with the various
  // applications
  window.seapweb
    ?.setUserId(
      sha256(
        removeSpecialCharacters(
          replaceAccents(
            opportunity?.person?.personalInformation?.name ??
              values.lastName +
                opportunity?.person?.personalInformation?.firstName ??
              values.firstName + birthDtXtn
          )
        )
          .normalize('NFKD')
          .toLowerCase()
      )
    )
    .catch(() => {
      console.warn('seapweb::setUserId an error ocurred');
    });

  // Load the XTN method to retrieve the XTN eventId that are very important to them
  window.seapweb
    ?.closeToken(false)
    .then((eventId: string) => {
      // save to database
      saveXtn(eventId);
    })
    .catch(() => {
      console.warn(
        'seapweb::closeToken an error occured when generating the eventId !'
      );
    });
}

export function triggerFirstFieldFirstNameAndLastNameError(
  formik: any,
  context?: RefinementCtx
) {
  const translation = t('basicInformations.errors.sameNameFirstName');
  if (
    formik?.values?.firstName &&
    formik?.values?.lastName &&
    formik?.values?.firstName === formik?.values?.lastName
  ) {
    if (context) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: translation,
      });
    } else {
      formik.setErrors({
        firstName: translation,
        lastName: translation,
      });
    }
  }
}

export const getInvalidLetters = (value: string) => {
  return Array.from(
    new Set(
      ...[
        (value ?? '')
          .split('')
          .filter((c) =>
            c.match(/[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+/)
          ),
      ]
    )
  ).join(', ');
};

export const setModalOpenedStyle = () => {
  if (document?.body) {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  }
};

export const setModalClosedStyle = () => {
  if (document?.body) {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  }
};

export const replaceStartSpacing = (
  field: string,
  value: string,
  formik: any
) => {
  const valueWithoutStartSpace = value.startsWith(' ')
    ? value.trimStart()
    : value;
  formik.setFieldValue(field, capitalize(valueWithoutStartSpace));
};

export const checkFormikRegexLength = (
  field: string,
  length: number,
  formik: any
) => {
  if (formik.values[field].length < length) {
    formik.errors[field] = t(`basicInformations.errors.${field}.size`);
  }
};
