import { useManagingStep, useUrlWebMarchand } from '@sweb-front/hooks';
import { setOpportunityStatus, updateOpportunityStatus, useAppDispatch } from '@sweb-front/store';
import { updateStatusOpp } from '@sweb-front/utils';
import { ERRORPAGE, OPPORTUNITYSTATUS } from '@vat/configuration';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useModalLeave = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { goToWebMarchand } = useManagingStep();
  const { getUrlCancel } = useUrlWebMarchand();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const returnToUrlWebMerchant = useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    goToWebMarchand(
      getUrlCancel(),
      'du lien "Accéder au site marchand" ',
      () => {
        updateStatusOpp(dispatch, OPPORTUNITYSTATUS.ANUL);
      },
      () => {
        navigate(ERRORPAGE);
      }
    );
  }, [isLoading]);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return {
    isLoading,
    returnToUrlWebMerchant,
  };
};

export default useModalLeave;
