import { OidcConfiguration } from '@axa-fr/oidc-client';
import {
  OidcProvider,
  TokenAutomaticRenewMode,
  TokenRenewMode,
} from '@axa-fr/react-oidc';
import { LoaderWaitingPages } from '@sweb-front/components';
import { SecondChanceErrorPage } from '@vat/layouts';
import { useCloseOpportunity } from '@vat/utils';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

export type OidcContextType = {
  configOidc: OidcConfiguration;
  configOidcError: boolean;
};

export const OidcContext = createContext<OidcContextType | undefined>(
  undefined
);

export const OidcContextProvider = ({ children }: { children: ReactNode }) => {
  const [configOidc, setConfigOidc] = useState<OidcConfiguration>();
  const [configOidcError, setConfigOidcError] = useState(false);
  const navigate = useNavigate();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  // logs every event from the oidc provider
  const onEvent = (configurationName: any, eventName: any, data: any) => {
    console.log(`oidc:${configurationName}:${eventName}`, data);
  };
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config: OidcConfiguration = await (
          await fetch('/assets/OidcConfig.json')
        ).json();
        if (config == undefined)
          throw new Error('Configuration file is undefined');
        if (config.authority_configuration == undefined)
          throw new Error(
            '`config.authority_configuration` property is undefined'
          );
        // fetching for oidc config will fail if you didn't authorized your app url on the oidc server
        const openidConfig = await (
          await fetch(
            `${config.authority_configuration.issuer}/.well-known/openid-configuration`
          )
        ).json();
        config.authority_configuration.authorization_endpoint =
          openidConfig.authorization_endpoint;
        config.authority_configuration.token_endpoint =
          openidConfig.token_endpoint;
        config.authority_configuration.revocation_endpoint =
          openidConfig.revocation_endpoint;
        config.authority_configuration.userinfo_endpoint =
          openidConfig.userinfo_endpoint;
        config.token_renew_mode = TokenRenewMode.access_token_invalid;
        config.token_automatic_renew_mode =
          TokenAutomaticRenewMode.AutomaticBeforeTokenExpiration;

        setConfigOidc(config);
      } catch (error) {
        setConfigOidcError(true);
        console.error(error);
      }
    };
    fetchConfig();
  }, []);
  return configOidc == undefined || configOidcError ? (
    <></>
  ) : (
    <OidcContext.Provider value={{ configOidc, configOidcError }}>
      <OidcProvider
        onEvent={onEvent}
        configuration={configOidc}
        // TODO: revoir tous les composants configurables
        loadingComponent={LoaderWaitingPages}
        authenticatingComponent={LoaderWaitingPages}
        callbackSuccessComponent={LoaderWaitingPages}
        authenticatingErrorComponent={() => {
          /* updateStatusOpportunity(
            OPPORTUNITYSTATUS.PINS,
            () => {
              // navigate(/contrat/KO);
            },
            (errCode) => {
              trackCustomAction(
                `error when calling update status PINS, error code : ${errCode}`
              );
              updateAbortErrorMessage(
                `error when calling update status PINS, error code : ${errCode}`
              );
              navigate(ERRORPAGE);
            }
          ); */
          return <SecondChanceErrorPage />;
        }}
        onSessionLost={() => {
          console.log('session lost');
        }}
      >
        {children}
      </OidcProvider>
    </OidcContext.Provider>
  );
};

export const useOidcContext = (): OidcContextType => {
  const context = useContext<OidcContextType | undefined>(OidcContext);
  if (!context) {
    throw new Error('useOidcContext must be used within a OidcProvider');
  }
  return context;
};

export default OidcContextProvider;
