import ReactDOM from 'react-dom/client';
import 'sanitize.css/sanitize.css';
import { BrowserRouter } from 'react-router-dom';
import { App } from '@vat/layouts';
import { StoreProvider } from '@sweb-front/components';
import store from '@sweb-front/store';
import './i18n';
import { OidcContextProvider } from '@sweb-front/hooks';
document
  .getElementById('prepare-env')
  .addEventListener('prepare-env-loaded', () => {
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
    );
    root.render(
      <BrowserRouter>
        <StoreProvider store={store}>
          <OidcContextProvider>
            <App />
          </OidcContextProvider>
        </StoreProvider>
      </BrowserRouter>
    );
  });
