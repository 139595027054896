import { Loader, LoaderWaitingPages, Section } from '@sweb-front/components';
import { usePageRedirection } from '@sweb-front/hooks';
import { LoadingWrapper } from '@sweb-front/styles';
import { OPENBANKING } from '@vat/configuration';
import { useManagingExternalApp } from '@vat/utils';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const OpenBanking = () => {
  const { t } = useTranslation();
  const { waitingPageBeforeOpenBanking } = useManagingExternalApp();
  const { isPageLoading } = usePageRedirection(OPENBANKING);

  useEffect(() => {
    if (isPageLoading) {
      return;
    }
    waitingPageBeforeOpenBanking();
  }, [isPageLoading]);

  return !isPageLoading && <LoaderWaitingPages />;
};

export default OpenBanking;
