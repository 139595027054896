import { Title } from '@sweb-front/components';
import { DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const ContractNotEligibleErrorPageWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media screen and ${DESKTOP} {
    margin-bottom: 4rem;
  }
`;

export const InformationMessage = styled.span`
  margin-top: 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-700);
`;

export const ClientMessage = styled.span`
  margin-top: 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-700);
`;

export const CenterWrapper = styled.div`
  margin: 0 auto;
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(2.4rem - 1.6rem);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  padding: 0;
  text-align: center;
  margin: 1.6rem 0 0 0;
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: 'Ubuntu Bold';
`;
