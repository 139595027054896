import { LoadingWrapper } from '@sweb-front/styles';
import Section from '../Section';
import Loader from '../Loader';
import { useTranslation } from 'react-i18next';

export const LoaderWaitingPages = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <LoadingWrapper>
        <Loader
          isLoading
          primaryText={t('common.loader.shortText')}
          secondaryText={t('common.loader.longText')}
          isTextInline={false}
        />
      </LoadingWrapper>
    </Section>
  );
};
