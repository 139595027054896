import CustomedBubbleInfo from 'src/libs/components/CustomedBubbleInfo/CustomedBubbleInfo';
import { DESKTOP, TAB } from '@sweb-front/utils';

import styled from 'styled-components';

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & #modal-kyc-cin {
    width: 100%;
    position: absolute;
    margin-top: -3.7rem;
    border: none;
  }

  @media screen and ${TAB} {
    & #modal-kyc-cin {
      min-height: inherit;
      margin-top: 0.4rem;
      width: 100%;
      max-width: 100%;
      border: none;
      position: relative;
    }
  }

  @media screen and ${DESKTOP} {
    & #modal-kyc-cin {
      min-height: inherit;
      margin-top: 0;
      width: 95%;
      max-width: 95%;
      border: none;
      position: relative;
    }
  }
`;

export const CustomedBubbleInfoWrapper = styled((props) => (
  <CustomedBubbleInfo {...props} />
))``;

export const BannerWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 99;
  top: -2.8rem;
  left: 0%;
  opacity: 0;
  transition: transform 800ms ease-out;

  @media screen and ${TAB} {
    top: 0;
    position: relative;
  }

  @media screen and ${DESKTOP} {
    position: relative;
    top: 0;
    left: 1.4rem;
  }

  &.open {
    transform: translateX(0rem);
    opacity: 1;
  }

  &.close {
    transform: translateX(100%);
    opacity: 0;
  }

  &.clear-component {
    height: 0;
  }
`;
