import { useUrlWebMarchand } from '@sweb-front/hooks';
import {
  isStringEmpty,
  trackCustomAction,
  trackEvent,
} from '@sweb-front/utils';
import {
  ERRORPAGE,
  REFUSEDREPONSE,
  SUCCESSREPONSE,
  WAITINGRESPONSE,
} from '@vat/configuration';
import { useState, useCallback, useEffect } from 'react';
const useRedirectionComponent = (
  delay: number,
  isErrorOccuredInPage: boolean,
  currentPage = ''
) => {
  const [timer, setTimer] = useState(0);
  const { getUrlDone, getUrlError, getUrlKO, getUrlLater } =
    useUrlWebMarchand();

  const onBackToWebMerchant = useCallback(() => {
    let url = getUrlDone();
    if (isErrorOccuredInPage || currentPage === ERRORPAGE) {
      url = getUrlError();
    } else if (currentPage === REFUSEDREPONSE) {
      url = getUrlKO();
    } else if (currentPage === WAITINGRESPONSE) {
      url = getUrlLater();
    }
    if (!isStringEmpty(url)) {
      trackCustomAction(
        `redirection de l'utilisateur vers le site webMarchand, url de redirection : ${url}`
      );
      window.location.href = url;
    }
  }, []);

  const onClickShortcutRedirection = () => {
    trackEvent({
      event: 'interaction_formulaire',
      FormInteraction: 'Clic du lien de redirection',
    });
    onBackToWebMerchant();
  };

  useEffect(() => {
    const timeout = setInterval(() => {
      setTimer((prv) => prv + 100);
    }, 100);
    if (timer === delay * 1000) {
      if (currentPage === SUCCESSREPONSE) {
        trackEvent({
          event: 'popinDisplay',
          modaleName: 'E-Commerce : FV : redirection contrat',
        });
      }
      onBackToWebMerchant();
      clearInterval(timeout);
    }
    return () => {
      clearInterval(timeout);
    };
  }, [timer]);

  const progressPercentage = (timer / (delay * 1000)) * 100;

  return {
    progressPercentage,
    onBackToWebMerchant,
    onClickShortcutRedirection,
  };
};

export default useRedirectionComponent;
