import {
  useBreakpoints,
  useLoadingHook,
  useManagingStep,
} from '@sweb-front/hooks';
import { COUNTRY_ID } from '@sweb-front/services';
import {
  IOpportunityState,
  IParameterState,
  selectNavigation,
  setAddress,
  setAutocompletefieldValue,
  setCities,
  setCountries,
  setDepartements,
  setEmail,
  setIsFilledWithAutoComplete,
  setNationalities,
  setPerson,
  setPhone,
  updateParcoursNavigation,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { CHANELTYPE, IOpportunity } from '@sweb-front/types';
import {
  findPriOverdraftAmount,
  trackCustomAction,
  trackEvent,
  trackUser,
} from '@sweb-front/utils';
import {
  INFORMATIONMAGASIN,
  INFORMATIONPAGE,
  KYCEDITABLEFIELDS,
  SECTORCODES,
  SECTORLIST,
} from '@vat/configuration';
import {
  ErrorContext,
  MAX_LENGHT_BILLING_CITY,
  MAX_LENGHT_BIRTH_CITY,
  MIN_LENGHT_FIELD,
  capitalize,
  emailRegex,
  nameRegex,
  useAppOptions,
} from '@vat/utils';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import merge from 'lodash.merge';
import {
  FocusEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import {
  checkFormikRegexLength,
  fetchData,
  postData,
  replaceStartSpacing,
  retrieveAndSaveXtn,
  setIdHashed,
  triggerFirstFieldFirstNameAndLastNameError,
} from '../utils';
import { isMobile as isMobileDevice } from 'react-device-detect';

const computeDate = (date: Date) => {
  const day = date.getDate();
  const computedDay = day < 10 ? `0${day}` : day;
  const month = date.getMonth() + 1;
  const computedMonth = month < 10 ? `0${month}` : month;
  return `${date.getFullYear()}-${computedMonth}-${computedDay}`;
};

const TITLE_VALUES = ['MME', 'MR'] as const;
const INSTANT_DATE = new Date(Date.now());
const MAX_DATE = (() => {
  const date = new Date(INSTANT_DATE);
  date.setFullYear(INSTANT_DATE.getFullYear() - 18);
  return date;
})();
const MIN_DATE = (() => {
  const date = new Date(INSTANT_DATE);
  date.setFullYear(INSTANT_DATE.getFullYear() - 100);
  date.setDate(date.getDate() - 1);
  return date;
})();

export type UseInformationStoreFormProps = {
  opportunity: IOpportunityState;
  parameters: IParameterState;
  fields;
};

export const useInformationStoreForm = ({
  opportunity,
  parameters,
  fields,
}: UseInformationStoreFormProps) => {
  const { t } = useTranslation();
  const { setAppOptions } = useAppOptions();
  const { isDesktop } = useBreakpoints();
  const { goToNextStep, goBackToStep } = useManagingStep();
  const dispatch = useAppDispatch();
  const updateError = useContext(ErrorContext);
  const { person } = opportunity;
  const { webAPageEvent } = useAppSelector((store) => store.navigation.state);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();
  const appNavUtils = useAppSelector(selectNavigation);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isBirthNameFocused, setIsBirthNameFocused] = useState(false);
  const [showAutoCompletedField, setShowAutoCompletedField] = useState<boolean>(
    appNavUtils.isFilledWithAutoComplete ?? true
  );
  const [autocompleteValue, setAutocompleteValue] = useState<string>();
  const [selectedRecordAutocomplete, setSelectedRecordAutocomplete] =
    useState<Record<string, string>>();
  const [showModalLeave, setShowModalLeave] = useState<boolean>(false);
  const [birthCityBubbleInfo, onToggleBirthCityInfo] = useState(false);

  const [dopcOptin, setDopcOptin] = useState({
    optin: person?.cellPhone?.useBo ?? false, // we just need the cellPhone or email as they will have the same behaviour and value for the optin
    dopc: person?.address?.useBo ?? false, // dopc
  });

  const sectors = SECTORLIST.map((sector) => ({
    value: SECTORCODES[sector],
    label: t(`basicInformations.sectorList.${sector}`),
  }));

  const onUpdateOptin = (value) => {
    setDopcOptin((prev) => ({
      ...prev,
      optin: value,
    }));
    dispatch(
      setEmail({
        ...person.email,
        useBo: value,
      })
    );
    dispatch(
      setPhone({
        ...person.cellPhone,
        useBo: value,
      })
    );
  };

  const onUpdateDopc = (value) => {
    setDopcOptin((prev) => ({
      ...prev,
      dopc: value,
    }));
    dispatch(
      setAddress({
        ...person.address,
        useBo: value,
      })
    );
  };

  const schema = z.object({
    title: z.enum(TITLE_VALUES, {
      required_error: t('basicInformations.errors.civility'),
    }),
    firstName: z
      .string({
        required_error: t('basicInformations.errors.firstName.required'),
      })
      .regex(nameRegex, t('basicInformations.errors.firstName.format'))
      .min(2, t('basicInformations.errors.firstName.size'))
      .max(30, t('basicInformations.errors.firstName.size'))
      .superRefine((_, ctx) => {
        triggerFirstFieldFirstNameAndLastNameError(formik, ctx);
      }),
    lastName: z
      .string({
        required_error: t('basicInformations.errors.lastName.required'),
      })
      .regex(nameRegex, t('basicInformations.errors.lastName.format'))
      .min(2, t('basicInformations.errors.lastName.size'))
      .max(30, t('basicInformations.errors.lastName.size'))
      .superRefine((_, ctx) => {
        triggerFirstFieldFirstNameAndLastNameError(formik, ctx);
      }),
    birthName: z
      .string({
        required_error: t('basicInformations.errors.birthName.required'),
      })
      .regex(nameRegex, t('basicInformations.errors.birthName.format'))
      .min(2, t('basicInformations.errors.birthName.size'))
      .max(30, t('basicInformations.errors.birthName.size')),
    birthDt: z
      .date({
        errorMap: (issue, ctx) => {
          switch (issue.code) {
            case 'invalid_date':
              return {
                message: t('basicInformations.errors.birthDt.incorrectDate'),
              };
            default:
              return {
                message:
                  ctx.defaultError === 'Required'
                    ? t('basicInformations.errors.birthDt.required')
                    : t('basicInformations.errors.incorrectDate'),
              };
          }
        },
      })
      .min(MIN_DATE, t('basicInformations.errors.birthDt.ageLimit100'))
      .max(MAX_DATE, t('basicInformations.errors.birthDt.lessThan18')),
    birthCountryIsoCd: z
      .string({
        required_error: t('basicInformations.errors.birthCountry'),
      })
      .min(MIN_LENGHT_FIELD, t('basicInformations.errors.birthCountry')),
    birthDepartment: z
      .string({
        required_error: t('basicInformations.errors.birthDepartment'),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (formik.values.birthCountryIsoCd === 'FR' && value !== undefined)
          return;
        if (formik.values.birthCountryIsoCd !== 'FR') return;
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('basicInformations.errors.birthDepartment'),
        });
      }),
    birthCity: z
      .string({
        required_error: t('basicInformations.errors.birthCity.required'),
        invalid_type_error: t('basicInformations.errors.birthCity.format'),
      })
      .regex(
        /[a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-/\\\\]+$/,
        t('basicInformations.errors.birthCity.format')
      )
      .min(MIN_LENGHT_FIELD, t('basicInformations.errors.birthCity.minChar'))
      .superRefine((value, ctx) => {
        //For manual entry, limit the birth city to 90 characters
        //For manual entry, limit the birth city to 90 characters
        if (value) {
          if (value.length < MIN_LENGHT_FIELD) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('basicInformations.errors.birthCity.minChar'),
            });
          }
          if (
            !formik.values.autoCompleteField &&
            formik.values.birthCountryIsoCd !== 'FR'
          ) {
            if (value.length > MAX_LENGHT_BIRTH_CITY) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: t('basicInformations.errors.birthCity.maxChar'),
              });
            }
          }
        } else {
          if (formik.values.birthCountryIsoCd !== 'FR') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('basicInformations.errors.otherBirthCity.required'),
            });
          } else {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t(
                'basicInformations.errors.autocompleteBirthCity.emptyValue'
              ),
            });
          }
        }
      }),
    birthCityInseeCd: z.string().optional(),
    nationalityIsoCd: z.string({
      required_error: t('basicInformations.errors.nationality'),
    }),
    autoCompleteField: z.string().optional(),
    address: z
      .string({
        required_error: t('basicInformations.errors.address.required'),
      })
      .min(2, t('basicInformations.errors.address.required')),
    complement: z.string().optional(),
    postalCode: z
      .string({
        required_error: t('basicInformations.errors.postalCode.required'),
      })
      .regex(/^\d{5}$/, t('basicInformations.errors.postalCode.invalid')),
    city: z
      .string({
        required_error: t('basicInformations.errors.city.required'),
        invalid_type_error: t('basicInformations.errors.city.format'),
      })
      .min(MIN_LENGHT_FIELD, t('basicInformations.errors.city.minChar'))
      .max(MAX_LENGHT_BILLING_CITY, t('basicInformations.errors.city.maxChar')),
    email: z
      .string({
        required_error: t('basicInformations.errors.email.required'),
      })
      .regex(emailRegex, t('basicInformations.errors.email.invalid')),
    mobile: z
      .string({
        required_error: t('basicInformations.errors.mobile.required'),
        invalid_type_error: t('basicInformations.errors.mobile.invalid'),
      })
      .length(10, t('basicInformations.errors.mobile.invalid'))
      .regex(/^(06|07)\d{8}$/, t('basicInformations.errors.mobile.invalid')),
    dopc: z.boolean().optional(),
    optin: z.boolean().optional(),
    activitySector: z
      .string({
        required_error: t('basicInformations.errors.activitySectors'),
        invalid_type_error: t('basicInformations.errors.activitySectors'),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (
          fields.includes(INFORMATIONPAGE.KYCSOLVACT) &&
          value === undefined
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('basicInformations.errors.activitySectors'),
          });
        }
      }),
  });

  type InformationFormSchema = z.infer<typeof schema>;

  const initialValues = {
    title: person?.personalInformation?.title as InformationFormSchema['title'],
    firstName: person?.personalInformation?.firstName || undefined,
    lastName: person?.personalInformation?.name || undefined,
    birthName: person?.personalInformation?.birthName || undefined,
    birthDt: person?.personalInformation?.birthDt
      ? new Date(person?.personalInformation?.birthDt)
      : undefined,
    birthCountryIsoCd:
      person?.personalInformation?.birthCountryIsoCd || undefined,
    birthDepartment: person?.personalInformation?.birthDepartment || undefined,
    birthCity: person?.personalInformation?.birthCity || undefined,
    birthCityInseeCd:
      person?.personalInformation?.birthCityInseeCd || undefined,
    nationalityIsoCd: person?.personalInformation?.nationalityIsoCd || 'FR',
    autoCompleteField:
      (appNavUtils?.autoCompleteBirthCityValue?.autocompleteValue as string) ??
      '',
    activitySector: person?.profession?.ihmSector || undefined,
    address: person?.address?.streetAddress || undefined,
    complement: person?.address?.additionalAddress || undefined,
    postalCode: person?.address?.zipCd || undefined,
    city: person?.address?.city || undefined,
    email: person?.email?.address || undefined,
    mobile: person?.cellPhone?.phoneNb || undefined,
  } as const;

  const initialTouched = Object.entries(initialValues)
    .map(([key, value]) =>
      value !== undefined && value !== '' ? key : undefined
    )
    .filter((item) => item !== undefined)
    .reduce(
      (acc, item) =>
        item !== undefined
          ? { ...acc, [item]: item === 'nationalityIsoCd' ? true : false }
          : acc,
      {}
    );

  const validate = (values: unknown) => {
    const result = schema.safeParse(values);
    if (result.success === true) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };

  const onSubmit = (values: InformationFormSchema) => {
    if (isActionLoading) {
      return;
    }
    retrieveAndSaveXtn(opportunity, person, values);
    const isBirthCountryFR = values.birthCountryIsoCd === 'FR';
    const updateOpportunity: IOpportunity = {
      persons: [
        {
          ...person,
          personalInformation: {
            ...person?.personalInformation,
            title: values.title,
            firstName: values.firstName,
            name: values.lastName,
            birthName: values.birthName,
            birthDt: computeDate(values.birthDt),
            birthCountryIsoCd: values.birthCountryIsoCd,
            birthDepartment: isBirthCountryFR
              ? values.birthDepartment
              : undefined,
            birthCity: values.birthCity,
            birthCityInseeCd: isBirthCountryFR
              ? values.birthCityInseeCd
              : undefined,
            nationalityIsoCd: values.nationalityIsoCd,
          },
          address: {
            ...person.address,
            streetAddress: values.address,
            additionalAddress: values.complement,
            city: values.city || undefined,
            zipCd: values.postalCode,
            countryCd: 'FR',
          },
          email: {
            ...person.email,
            address: values.email,
          },
          cellPhone: {
            ...person.cellPhone,
            phoneNb: values.mobile,
          },
          profession: {
            ...person?.profession,
            ihmSector: values.activitySector,
          },
        },
      ],
      isMobile: isMobileDevice,
    };
    updateIsActionLoading(true);
    dispatch(
      setAutocompletefieldValue({
        ...selectedRecordAutocomplete,
        autocompleteValue,
      })
    );
    postData(
      'vendors/opportunities/v1/opportunity',
      updateOpportunity,
      (response: AxiosResponse<IOpportunity>) => {
        dispatch(
          updateParcoursNavigation({
            name: INFORMATIONMAGASIN,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        updateIsActionLoading(false);
        dispatch(
          setPerson(
            merge(updateOpportunity.persons[0], response.data?.persons?.[0])
          )
        );
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: INFORMATIONMAGASIN,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  };

  const formik = useFormik<Partial<InformationFormSchema>>({
    initialValues,
    initialTouched,
    validateOnChange: true,
    validateOnBlur: false,
    validate,
    onSubmit,
  });

  const fetchCountries = (token: string) => {
    fetchData(
      'localities/v1/countries',
      { headers: { token, countryID: COUNTRY_ID } },
      (data) => {
        dispatch(setCountries(data));
        dispatch(setNationalities(data));
      },
      () => {
        updateError();
      }
    );
  };

  const fetchDepartments = (token: string) => {
    fetchData(
      'localities/v1/departments',
      { headers: { token, countryID: COUNTRY_ID } },
      (data) => {
        dispatch(setDepartements(data));
      },
      () => {
        updateError();
      }
    );
  };

  const fetchCities = (departementCode?: string, token?: string) => {
    if (!departementCode) {
      dispatch(setCities([]));
      return;
    }
    fetchData(
      `localities/v1/${departementCode}/cities`,
      {
        headers: { token, countryID: COUNTRY_ID },
      },
      (data) => {
        dispatch(setCities(data));
      },
      () => {
        updateError();
      }
    );
  };

  const resetBirthCity = () => {
    formik.setFieldValue('birthCity', '');
    formik.setFieldValue('birthCityInseeCd', '');
  };

  const setBirthDepartment = (value?: string) => {
    if (formik.values.birthDepartment !== value) {
      resetBirthCity();
    }
    formik.setFieldValue('birthDepartment', value);
    fetchCities(value);
  };

  const trackEventPageLoaded = () => {
    if (opportunity?.opportunityIdExt) {
      const { financialDetails } = opportunity.offers[0].loans[0];
      const isMono: boolean = opportunity?.bagType === 'MONO';
      trackEvent({
        event: webAPageEvent ?? 'pageLoaded',
        Telesales:
          (opportunity?.distributionChannelCD === CHANELTYPE.TEL)?.toString() ??
          'false',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire KYC',
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        ContributorCode: opportunity.distributor.distributorNb,
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
          : undefined,
        opportunityIdHashed: setIdHashed(opportunity),
        pathType: isMono ? 'mono panier' : 'multi panier',
      });
    }
  };

  const fetchLocalitiesData = (token: string) => {
    trackCustomAction(`Fetch localitiesData : "${token}"`);
    fetchCountries(token);
    fetchDepartments(token);
    if (!showAutoCompletedField) {
      fetchCities(formik.values.birthDepartment, token);
    }
  };
  useEffect(() => {
    let timeoutID;
    if (opportunity?.opportunityIdExt != null) {
      trackUser(opportunity?.opportunityIdExt);
    }
    const token = localStorage.getItem('token');
    if (!token) {
      timeoutID = setTimeout(() => {
        const retryToken = localStorage.getItem('token');
        if (!retryToken)
          trackCustomAction(`No token in LocalStorage: "${retryToken}"`);
        // ANYWAY do FETCH DATA To GET 401
        fetchLocalitiesData(retryToken);
      }, 1000);
    } else {
      fetchLocalitiesData(token);
    }

    trackEventPageLoaded();
    setAppOptions((state) => ({ ...state, isMounted: true }));
    return () => {
      setAppOptions((state) => ({ ...state, isMounted: false }));
      timeoutID && clearTimeout(timeoutID);
    };
  }, []);

  const onLastNameFocus = () => setIsLastNameFocused(true);

  const onLastNameBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const trimmedEndValue = e.target.value.replace(/\s+$/, '');
    if (trimmedEndValue !== e.target.value) {
      e.target.value = trimmedEndValue;
      formik.values.lastName = trimmedEndValue;
    }
    checkFormikRegexLength('lastName', 2, formik);
    setIsLastNameFocused(false);
    formik.handleBlur(e);
  };

  const onLastNameChange = (value: string) =>
    replaceStartSpacing('lastName', value, formik);

  const onFirstNameFocus = () => setIsFirstNameFocused(true);

  const onFirstNameBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const trimmedEndValue = e.target.value.replace(/\s+$/, '');
    if (trimmedEndValue !== e.target.value) {
      e.target.value = trimmedEndValue;
      formik.values.firstName = trimmedEndValue;
    }
    checkFormikRegexLength('firstName', 2, formik);
    setIsFirstNameFocused(false);
    formik.handleBlur(e);
  };

  const onFirstNameChange = (value: string) =>
    replaceStartSpacing('firstName', value, formik);

  const onBirthNameFocus = () => setIsBirthNameFocused(true);

  const onBirthNameBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const trimmedEndValue = e.target.value.replace(/\s+$/, '');
    if (trimmedEndValue !== e.target.value) {
      e.target.value = trimmedEndValue;
      formik.values.birthName = trimmedEndValue;
    }
    checkFormikRegexLength('birthName', 2, formik);
    setIsBirthNameFocused(false);
    formik.handleBlur(e);
  };

  const onBirthNameChange = (value: string) =>
    replaceStartSpacing('birthName', value, formik);

  const onShowModalLeave = useCallback((val: boolean) => {
    setShowModalLeave(val);
  }, []);

  const onShowModalLeaveFn = () => {
    onShowModalLeave(false);
  };

  const onChangeAutoCompletedField = async (res, concatenedRes) => {
    formik.setFieldValue(
      KYCEDITABLEFIELDS.BirthCityInseeCd,
      res?.codePays === 'FR' ? res?.codeInsee : ''
    );
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthDepartment, res?.province);
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCountry, res?.codePays);
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCity, res?.localite);
    setSelectedRecordAutocomplete(res);
    setAutocompleteValue(concatenedRes);
  };

  const onSuggestionAutoCompletedFieldClick = () => {
    setShowAutoCompletedField(false);
    dispatch(setIsFilledWithAutoComplete(false));
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCityInseeCd, '');
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthDepartment, '');
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCountry, '');
    formik.setFieldValue(KYCEDITABLEFIELDS.BirthCity, '');
  };

  const onBlurAutocomplete = (e, rec, internalValue) => {
    if (internalValue === undefined) {
      setSelectedRecordAutocomplete(
        appNavUtils?.autoCompleteBirthCityValue
          ?.selectedRecordAutocomplete as Record<string, string>
      );
      setAutocompleteValue(
        appNavUtils?.autoCompleteBirthCityValue?.autocompleteValue as string
      );
    } else {
      setSelectedRecordAutocomplete(rec);
      setAutocompleteValue(internalValue);
    }
    formik.handleBlur(e);
  };

  return {
    formik,
    setBirthDepartment,
    resetBirthCity,
    t,
    isBirthCountryFR: formik.values.birthCountryIsoCd === 'FR',
    onUpdateOptin,
    goBackToStep,
    onUpdateDopc,
    dopcOptin,
    isDesktop,
    sectors,
    isActionLoading,
    showAutoCompletedField,
    autocompleteBirthCity: autocompleteValue,
    defaultAutocompleteValue: appNavUtils?.autoCompleteBirthCityValue
      ?.autocompleteValue as string,
    onChangeAutoCompletedField,
    onSuggestionAutoCompletedFieldClick,
    onBlurAutocomplete,
    isLastNameFocused,
    onLastNameFocus,
    onLastNameBlur,
    isFirstNameFocused,
    onFirstNameFocus,
    onFirstNameBlur,
    isBirthNameFocused,
    onBirthNameFocus,
    onBirthNameBlur,
    showModalLeave,
    onShowModalLeaveFn,
    onFirstNameChange,
    onLastNameChange,
    onBirthNameChange,
    birthCityBubbleInfo,
    onToggleBirthCityInfo,
  };
};
