import { useOidc } from '@axa-fr/react-oidc';
import { axiosInstance } from '@sweb-front/services';
import { trackCustomAction } from '@sweb-front/utils';
import {
  CONTRACTNOTFOUNDERRORPAGE,
  ERRORKEY,
  ERRORPAGE,
  OPPORTUNITYSTATUS,
  PAGECMP,
  WAITING_PAGE_MIRE,
} from '@vat/configuration';
import { updateStatusOffer } from '@vat/services';
import { useCloseOpportunity } from '@vat/utils';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useLoadingHook from './useLoadingHook';
import useManagingStep from './useManagingStep';

const useOidcHelper = () => {
  const { logout, isAuthenticated, login } = useOidc();
  const navigate = useNavigate();
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const { goToNextStep, goToNextStepCustomStep } = useManagingStep();
  const { updateIsActionLoading } = useLoadingHook();

  const didNotAuthenticated = (searchParams) => {
    return (
      searchParams
        .get('error_description')
        ?.includes('Authentication failed') &&
      searchParams.get('error')?.includes('access_denied')
    );
  };

  const cleanOidcContextIfNeeded = useCallback(
    (params: URLSearchParams, storedToken?: string) => {
      const hasParamsToken = params.has('token') || params.has('shortToken');
      const isNewToken =
        hasParamsToken && params.get('token') !== (storedToken ?? '');
      if (isNewToken && isAuthenticated) {
        logout(null, { 'no_reload:oidc': 'true' });
        console.log('new token, old oidc context cleared');
      }
    },
    [isAuthenticated]
  );

  const loginOrGoToNextStep = useCallback(() => {
    if (isAuthenticated) {
      updateIsActionLoading(false);
      goToNextStep();
    } else {
      login(WAITING_PAGE_MIRE);
    }
  }, [isAuthenticated]);

  const processOidcReturn = async (opportunity) => {
    try {
      // axios:
      const { data } = await axiosInstance().get(`/vendors/clients/v1/data`);
      if (data?.flexTradeInVerified) {
        // update statut PINO
        return updateStatusOffer(
          OPPORTUNITYSTATUS.PINO,
          opportunity,
          () => {
            goToNextStepCustomStep(PAGECMP.IDCTLM);
          },
          (errCode) => {
            trackCustomAction(
              `error when calling update status PINO, error code : ${errCode}`
            );
            updateAbortErrorMessage(
              `error when calling update status PINO, error code : ${errCode}`
            );
            localStorage.setItem(ERRORKEY, 'PINO');
            navigate(ERRORPAGE);
          }
        );
      } else {
        if (data?.flexReasonCd?.toLowerCase() === 'contract_not_found') {
          // update statut PINC
          return updateStatusOffer(
            OPPORTUNITYSTATUS.PINC,
            opportunity,
            () => {
              navigate(CONTRACTNOTFOUNDERRORPAGE);
            },
            (errCode) => {
              trackCustomAction(
                `error when calling update status PINC, error code : ${errCode}`
              );
              updateAbortErrorMessage(
                `error when calling update status PINC, error code : ${errCode}`
              );
              localStorage.setItem(ERRORKEY, 'PINC');
              navigate(ERRORPAGE);
            }
          );
        } else {
          if (data?.flexReasonCd?.toLowerCase() === 'contract_not_eligible') {
            // update statut PINS
            return updateStatusOffer(
              OPPORTUNITYSTATUS.PINS,
              opportunity,
              () => {
                navigate(CONTRACTNOTFOUNDERRORPAGE);
              },
              (errCode) => {
                trackCustomAction(
                  `error when calling update status PINS, error code : ${errCode}`
                );
                updateAbortErrorMessage(
                  `error when calling update status PINS, error code : ${errCode}`
                );
                localStorage.setItem(ERRORKEY, 'PINS');
                navigate(ERRORPAGE);
              }
            );
          }
        }
      }
    } catch (errCode) {
      trackCustomAction(
        `error when calling /clients/v1/data, error code : $errCode`
      );
    }
    // update statut PINK
    updateStatusOffer(
      OPPORTUNITYSTATUS.PINK,
      opportunity,
      () => {},
      (errCode) => {
        trackCustomAction(
          `error when calling update status PINK, error code : ${errCode}`
        );
        updateAbortErrorMessage(
          `error when calling update status PINK, error code : ${errCode}`
        );
      }
    );
    localStorage.setItem(ERRORKEY, 'errorFlexTradein');
    navigate(ERRORPAGE);
  };

  return {
    cleanOidcContextIfNeeded,
    processOidcReturn,
    loginOrGoToNextStep,
    didNotAuthenticated,
  };
};

export default useOidcHelper;
