import { Section } from '@sweb-front/components';
import { DESKTOP, TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const MainSectionWrapper = styled((props) => <Section {...props} />)`
  font-size: 1.2rem;
`;

export const StyledParagraph = styled.div`
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 1.6rem 0;

  & .cookies-button {
    display: block;
    text-align: center;

    @media screen and ${DESKTOP} {
      margin-top: 0;
      text-align: inherit;
    }
  }

  .ot-sdk-show-settings {
    background: ${({ theme }) => theme.vendor.colors.primary};
    border: 1px solid ${({ theme }) => theme.vendor.colors.primary} !important;
    color: #fff !important;

    &:hover {
      background-color: ${({ theme }) => theme.vendor.colors.hover} !important;
    }
  }
`;

export const StyledTitle = styled.h1`
  font-family: 'Ubuntu Bold';
  color: var(--neutrals-700);
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: bold;
`;

export const BoldSpan = styled.span`
  font-family: 'Open Sans Bold';
`;
