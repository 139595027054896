import React, { ReactElement, ReactNode, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  CloseButtonWrapper,
  ModalBackground,
  ModalWrapper,
  StyledCSSBackgroundTransition,
  StyledCSSModalTransition,
  StyledCloseButton,
  StyledCloseIcon,
} from './styles';
import { useBreakpoints } from '@sweb-front/hooks';

export type ModalProps = {
  id?: string;
  isOpened: boolean;
  closeModal: () => void;
  onClose?: () => void;
  children?: ReactNode;
  isSpecificHeader?: boolean;
  timeout?: number;
};

const Modal = ({
  children,
  id,
  isOpened,
  closeModal,
  onClose = () => {},
  isSpecificHeader = false,
  timeout = 275,
}: ModalProps) => {
  const modalRef = useRef(null);
  const backgroundRef = useRef(null);
  const { isMobile } = useBreakpoints();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const handleOverflowing = (overflowing: boolean) =>
    setIsOverflowing(overflowing);
  return (
    <StyledCSSBackgroundTransition id={`${id}-modal-background`}>
      <CSSTransition
        in={isOpened}
        nodeRef={backgroundRef}
        timeout={0}
        classNames="modal-background"
      >
        <ModalBackground ref={backgroundRef} id={id} className={id}>
          <StyledCSSModalTransition id={id} className={id}>
            <CSSTransition
              in={isOpened}
              nodeRef={modalRef}
              timeout={timeout}
              classNames="modal"
            >
              <ModalWrapper
                ref={modalRef}
                id={id}
                className={id}
                isOverflowing={isOverflowing}
                style={
                  id === 'recap-modal-tradeIn'
                    ? {
                        top:
                          isMobile && isOverflowing
                            ? '1.4rem'
                            : !isMobile && '4rem',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        height: isOverflowing ? '100%' : '',
                      }
                    : {}
                }
              >
                {!isSpecificHeader && (
                  <CloseButtonWrapper className={`${id}`}>
                    <StyledCloseButton
                      type="button"
                      onClick={() => {
                        onClose();
                        closeModal();
                      }}
                    >
                      <StyledCloseIcon />
                    </StyledCloseButton>
                  </CloseButtonWrapper>
                )}
                {id === 'recap-modal-tradeIn'
                  ? React.cloneElement(children as ReactElement, {
                      onOverflowing: handleOverflowing,
                    })
                  : children}
              </ModalWrapper>
            </CSSTransition>
          </StyledCSSModalTransition>
        </ModalBackground>
      </CSSTransition>
    </StyledCSSBackgroundTransition>
  );
};

export default Modal;
