import SecondChanceImage from './SecondChanceImage';

import {
  ContentWrapper,
  SecondChanceErrorPageWrapper,
  NavigationButton,
  IdentificationMessage,
  TitleCmp,
  WithoutIdentificationTitle,
  WithoutIdentificationList,
  ListOne,
  ListTwo,
  ListThree,
  BlockedIdentificationTitle,
  BlockedIdentificationDescription,
  WithoutIdentificationWrapper,
} from './styles';
import useSecondChanceErrorPage from './useSecondChanceErrorPage';
import { t } from 'i18next';
import { Button } from '@vat/components';
import CetelemHeaderLogo from 'src/layouts/CetelemLogoHeader';
import { Trans } from 'react-i18next';

const SecondChanceErrorPage = () => {
  const { onBackToIdentification } = useSecondChanceErrorPage();

  return (
    <SecondChanceErrorPageWrapper data-testid="SecondChanceErrorPageWrapper">
      <CetelemHeaderLogo />
      <SecondChanceImage />
      <ContentWrapper>
        <TitleCmp>
          {t('orderSummary.flexibleTradeIn.errors.secondChance.identification')}
        </TitleCmp>
        <IdentificationMessage id="identification">
          {t(
            'orderSummary.flexibleTradeIn.errors.secondChance.identificationDescription'
          )}
        </IdentificationMessage>
        <WithoutIdentificationWrapper>
          <WithoutIdentificationTitle id="withoutIdentificationTitle">
            {t(
              'orderSummary.flexibleTradeIn.errors.secondChance.withoutIdentification.title'
            )}
          </WithoutIdentificationTitle>
          <WithoutIdentificationList id="withoutIdentificationList">
            <ListOne>
              <Trans
                i18nKey="orderSummary.flexibleTradeIn.errors.secondChance.withoutIdentification.bloc1"
                components={{
                  bloc1: <strong id="bloc1">bloc1</strong>,
                }}
              />
            </ListOne>
            <ListTwo>
              {t(
                'orderSummary.flexibleTradeIn.errors.secondChance.withoutIdentification.bloc2'
              )}
            </ListTwo>
            <ListThree>
              <Trans
                i18nKey="orderSummary.flexibleTradeIn.errors.secondChance.withoutIdentification.bloc3"
                components={{
                  bloc3: <strong id="bloc3">bloc3</strong>,
                }}
              />
            </ListThree>
          </WithoutIdentificationList>
        </WithoutIdentificationWrapper>
        <BlockedIdentificationTitle id="blockedIdentificationTitle">
          {t(
            'orderSummary.flexibleTradeIn.errors.secondChance.blockedIdentification.title'
          )}
        </BlockedIdentificationTitle>
        <BlockedIdentificationDescription id="blockedIdentificationDescription">
          {t(
            'orderSummary.flexibleTradeIn.errors.secondChance.blockedIdentification.description'
          )}
        </BlockedIdentificationDescription>
      </ContentWrapper>
      <NavigationButton id="navigationButton">
        <Button
          id="button-leave"
          level="primary"
          onClick={onBackToIdentification}
          isBold
        >
          {t('basicInformations.backToIdentification')}
        </Button>
      </NavigationButton>
    </SecondChanceErrorPageWrapper>
  );
};

export default SecondChanceErrorPage;
