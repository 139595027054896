import ContractNotEligibleImage from './ContractNotEligibleImage';

import {
  CenterWrapper,
  ContentWrapper,
  ContractNotEligibleErrorPageWrapper,
  TitleCmp,
  InformationMessage,
  ClientMessage,
} from './styles';
import { t } from 'i18next';
import CetelemHeaderLogo from 'src/layouts/CetelemLogoHeader';

const ContractNotFoundErrorPage = () => {
  return (
    <ContractNotEligibleErrorPageWrapper data-testid="ContractNotEligibleErrorPageWrapper">
      <CetelemHeaderLogo />
      <ContractNotEligibleImage />
      <CenterWrapper>
        <ContentWrapper>
          <TitleCmp>
            {t('orderSummary.flexibleTradeIn.errors.contractNotEligible.title')}
          </TitleCmp>
          <InformationMessage id="information">
            {t(
              'orderSummary.flexibleTradeIn.errors.contractNotEligible.information'
            )}
          </InformationMessage>
          <ClientMessage id="client">
            {t(
              'orderSummary.flexibleTradeIn.errors.contractNotEligible.client'
            )}
          </ClientMessage>
        </ContentWrapper>
      </CenterWrapper>
    </ContractNotEligibleErrorPageWrapper>
  );
};

export default ContractNotFoundErrorPage;
