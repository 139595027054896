import { LoaderWaitingPages } from '@sweb-front/components';

import { useManagingExternalApp } from '@vat/utils';
import { useEffect } from 'react';


const InitOnbo = () => {
  const { waitingPageBeforeOnbo } = useManagingExternalApp();

  useEffect(() => {
    waitingPageBeforeOnbo();
  }, []);

  return (
    <LoaderWaitingPages />
  );
};

export default InitOnbo;
