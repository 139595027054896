import {
  IBankCardPaymentRequest,
  IBankCardPaymentResponse,
  IDoAuthorizationRequest,
  IDoAuthorizationResponse,
  IDsCardPaymentResponse,
  IOnboardingEsignResponse,
  PlanBResponse,
} from '@sweb-front/types';
import { AxiosHeaderValue } from 'axios';
import axiosInstance from './httpCommon';

export interface OnboardingUrlResponse {
  url: string;
}

export const updateCardPayment = async (
  cardId: string,
  cardRef: string,
  returnCode: string,
  returnValue: string,
  successCallback?: (res: IBankCardPaymentResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const updateCardPaymentRequest: IBankCardPaymentRequest = {
      cardId,
      cardRef,
      cardCreationCd: returnCode,
      cardCreationValue: returnValue,
    };
    const response = await axiosInstance().post(
      `vendors/opportunities/v1/payment/bank-card`,
      updateCardPaymentRequest
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const getOnboardingUrl = async (
  returnUrl: string,
  successCallback?: (response: OnboardingUrlResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().get(
      `vendors/opportunities/v1/onboarding?returnUrl=${returnUrl}`
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const getDsCardUrl = async (
  returnUrl: string,
  successCallback?: (response: IDsCardPaymentResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().get(
      `vendors/opportunities/v1/card3ds?returnUrl=${returnUrl}`
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const fetchAdresses = async (
  adresse: string,
  successCallback: (response) => void
) => {
  try {
    const response = await axiosInstance().get('localities/v1/addresses', {
      params: { adresse },
    });
    successCallback?.(response.data);
  } catch (err) {
    console.error(err);
  }
};

export const check3DSReturnCode = async (
  onboardingErrorCd: string,
  successCallback?: (response: IOnboardingEsignResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const response = await axiosInstance().post(
      `vendors/opportunities/v1/onboarding/esign`,
      {
        onboardingErrorCd,
      }
    );
    successCallback?.(response.data);
  } catch (errCode) {
    errorCallback?.(errCode as number);
  }
};

export const doAuthorization = async (
  threeDsRef: string,
  cardCreationCd: string,
  cardCreationValue: string,
  successCallback?: (response: IDoAuthorizationResponse) => void,
  errorCallback?: (err: number) => void
) => {
  try {
    const doAuthorizationRequest: IDoAuthorizationRequest = {
      threeDsRef,
      cardCreationCd,
      cardCreationValue,
    };
    const response = await axiosInstance().post(
      `vendors/opportunities/v1/payment/doAuthorization`,
      doAuthorizationRequest
    );
    successCallback?.(response?.data);
    return response?.data;
  } catch (errCode) {
    errorCallback?.(errCode as number);
    throw new Error(errCode);
  }
};

export const updateStatusOffer = async (
  statusCd: string,
  opportunity,
  successCallback?: (response) => void,
  errorCallback?: (err: number) => void
) => {
  const offer = opportunity?.offers?.[0];
  try {
    const response = await axiosInstance().post(
      'vendors/opportunities/v1/opportunity',
      {
        statusCd: opportunity?.statusCd,
        offers: [
          {
            offerId: offer?.offerId,
            loans: offer?.loans?.map((loan) => ({
              loanId: loan?.loanId,
              statusCd,
            })),
          },
        ],
      }
    );
    successCallback?.(response?.data);
    return response?.data;
  } catch (errCode) {
    errorCallback?.(errCode as number);
    throw new Error(errCode);
  }
};

export const abortOpportunity = async (
  token: string,
  type = 'TECHNICAL',
  reason?: string
) => {
  return axiosInstance()
    .post(
      `vendors/opportunities/v1/opportunity/abort`,
      {
        reason: (reason ?? '').replace(', $OtherReasons', ''),
        type,
      },
      { headers: { token } }
    )
    .catch((error) => console.error('Error aborting opportunity', error));
};

export const getAcceptationPolicyPromise = (
  paymentMode: 'RIB' | 'CB',
  step: 'KYC' | 'SOLVA' | 'ASSU' | 'CB' | 'ONBO' | 'BANK_3DS' | 'DECISION_RVO'
) => {
  return axiosInstance().get(
    `vendors/opportunities/v1/opportunity/score?paymentMode=${paymentMode}&step=${step}`
  );
};

export const createECard = async (token: string) => {
  return axiosInstance().post(`vendors/opportunities/v1/e-card`, {
    headers: { token },
  });
};

export const checkAvailability = (
  planB: boolean,
  alreadyCalled: string
): Promise<PlanBResponse> => {
  return planB === undefined && !(alreadyCalled === 'true')
    ? axiosInstance().post('vendors/opportunities/v1/planb')
    : Promise.resolve({ data: { planB } });
};

export const saveXtn = async (eventId: string) => {
  try {
    return await axiosInstance().post(
      'vendors/opportunities/v1/xtn/xtn-event',
      {
        eventId,
        clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      {
        headers: {
          headers: {
            customHeader: 'souscription',
            // x-real-ip is transmitted by nginx on deployed env, locally this call will make a HTTP 4xx because device.ip is mandatory
          } as unknown as AxiosHeaderValue,
        },
      }
    );
  } catch {
    console.warn('XTN::xtnEvent save xtn failed to execute');
    return {};
  }
};

export default updateCardPayment;
