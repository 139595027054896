import { ImgWrapper } from './styles';

export const ContractNotFoundImage = () => {
  return (
    <ImgWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.33342 20C3.33342 29.2048 10.7953 36.6667 20.0001 36.6667C29.2048 36.6667 36.6667 29.2048 36.6667 20C36.6667 10.7953 29.2048 3.33335 20.0001 3.33335C10.7953 3.33335 3.33342 10.7953 3.33342 20ZM4.92072 20C4.92072 11.6719 11.672 4.92065 20.0001 4.92065C28.3282 4.92065 35.0794 11.6719 35.0794 20C35.0794 28.3281 28.3282 35.0794 20.0001 35.0794C11.672 35.0794 4.92072 28.3281 4.92072 20ZM18.6773 12.0635C18.6773 11.333 19.2695 10.7407 20 10.7407C20.7305 10.7407 21.3228 11.333 21.3228 12.0635L21.3228 21.3228C21.3228 22.0533 20.7305 22.6455 20 22.6455C19.2695 22.6455 18.6773 22.0533 18.6773 21.3228V12.0635ZM21.9841 27.2751C21.9841 26.1793 21.0958 25.291 20 25.291C18.9042 25.291 18.0159 26.1793 18.0159 27.2751C18.0159 28.371 18.9042 29.2593 20 29.2593C21.0958 29.2593 21.9841 28.371 21.9841 27.2751Z"
          fill="#268038"
        />
      </svg>
    </ImgWrapper>
  );
};

export default ContractNotFoundImage;
