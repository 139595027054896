import ContractNotFoundImage from './ContractNotFoundImage';

import {
  CenterWrapper,
  ContentWrapper,
  ContractNotFoundErrorPageWrapper,
  NavigationButton,
  TitleCmp,
  InformationMessage,
  ClientMessage,
} from './styles';
import useContractNotFoundErrorPage from './useContractNotFoundErrorPage';
import { t } from 'i18next';
import { Button } from '@vat/components';
import CetelemHeaderLogo from 'src/layouts/CetelemLogoHeader';

const ContractNotFoundErrorPage = () => {
  const { onBackToIdentification } = useContractNotFoundErrorPage();

  return (
    <ContractNotFoundErrorPageWrapper data-testid="ContractNotFoundErrorPageWrapper">
      <CetelemHeaderLogo />
      <ContractNotFoundImage />
      <CenterWrapper>
        <ContentWrapper>
          <TitleCmp>
            {t('orderSummary.flexibleTradeIn.errors.contractNotFound.title')}
          </TitleCmp>
          <InformationMessage id="information">
            {t(
              'orderSummary.flexibleTradeIn.errors.contractNotFound.information'
            )}
          </InformationMessage>
          <ClientMessage id="client">
            {t('orderSummary.flexibleTradeIn.errors.contractNotFound.client')}
          </ClientMessage>
        </ContentWrapper>
        <NavigationButton id="navigationButton">
          <Button
            id="button-leave"
            level="primary"
            onClick={onBackToIdentification}
            isBold
          >
            {t('basicInformations.backToIdentification')}
          </Button>
        </NavigationButton>
      </CenterWrapper>
    </ContractNotFoundErrorPageWrapper>
  );
};

export default ContractNotFoundErrorPage;
