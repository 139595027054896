import {
  selectNavigation,
  selectTheme,
  useAppSelector,
} from '@sweb-front/store';
import { useCallback, useEffect } from 'react';

const usePingDownErrorPage = () => {
  const appNavigation = useAppSelector(selectNavigation);
  const themeName = useAppSelector(selectTheme);
  const mainContainer = document.getElementById('Main-container');

  const onBackToWebMerchant = useCallback(() => {}, [
    appNavigation?.routingContexts,
  ]);

  useEffect(() => {
    // The user does not have to now the path of our application
    window.history.replaceState({}, document.title, '/');

    // This style is only for this page
    if (mainContainer) {
      mainContainer.style.width = '100%';
    }
  }, []);

  // Display none cookies policy
  if (themeName === 'CETELEM') {
    const cookiesPolicy = document.querySelectorAll('.cookies-policy');
    if (cookiesPolicy?.length) {
      cookiesPolicy[0].classList.add('d-none');
    }
  }

  return {
    onBackToWebMerchant,
  };
};

export default usePingDownErrorPage;
