import { Title } from '@sweb-front/components';
import { DESKTOP, MOBILE, TAB } from '@sweb-front/utils';
import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  padding: 1.6rem 0 0 0;
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin: 3.2rem 0 4rem 0;
  &#inputWrapperInformationStoreForm > div {
    &:not(:first-child) {
      margin-top: 1.6rem;
    }
  }
  & gender-input {
    display: flex;
    font-size: 1.6rem;
    & selected-button {
      font-family: 'Open Sans';
      font-size: 1.6rem;
      line-height: 2.4rem;
      flex-grow: 1;
      padding: 1.5rem 0;
    }
  }
`;

export const ActivitySectorContainer = styled.div`
  margin: 0;
`;

export const FirstInputWrapper = styled(InputWrapper)`
  margin: 0.8rem 0 1.6rem 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  padding: 1.6rem 1.6rem 0.8rem 1.6rem;
  justify-content: space-between;
`;

export const FirstDivisionWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2.4rem;
  cursor: default;
  margin-right: 0.8rem;
`;

export const IconWrapper = styled.div`
  height: 100%;
  width: 2.4rem;
  margin-right: 0.8rem;
`;

export const HeaderSpanContentWrapper = styled.div``;

export const SpanContent = styled.span`
  font-family: 'Open Sans Bold';
  display: block
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2.4rem;
  color: var(--neutrals-700);
`;

export const SecondDivisionWrapper = styled.div`
  cursor: pointer;
`;

export const ActivitySectorTitle = styled.h3`
  font-family: 'Open Sans Bold';
  font-size: 1.6rem;
  color: var(--neutrals-500);
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SVGIconWrapper = styled.div`
  align-self: center;
  & base-icon {
    margin-right: 0.8rem;
    --color-icon: var(--information-500);
    --icon-width: 6rem;
    --icon-height: 6rem;
  }
`;

export const SVGIconErrorWrapper = styled.div`
  align-self: center;
  & base-icon {
    margin-right: 0.8rem;
    --color-icon: var(--red-400);
    --icon-width: 6rem;
    --icon-height: 6rem;
  }
`;

export const SVGIconReturnWrapper = styled.div`
  align-self: center;
  & base-icon {
    margin-right: 1rem;
    --color-icon: var(--neutrals-0);
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 2rem;
  & span {
    font-size: 1.6rem;
  }
  &.informationFormContentWrapper {
    padding: 0 1.6rem;
  }
`;

export const TitleWrapper = styled((props) => <Title {...props} />)`
  margin-bottom: 1.2rem;
  color: var(--neutrals-700);
`;

export const TitleContent = styled.h1`
  margin: 0 0 1.6rem 0;
  font-family: 'Ubuntu Bold';
  font-size: 2rem;
  line-height: 2.8rem;
  color: var(--neutrals-700);
`;

export const DescriptionWrapper = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-500);
  & .strong {
    margin-top: 1.2rem;
  }
`;

export const DescriptionContentWrapper = styled.div`
  padding: 0 1.6rem;
  margin-bottom: 1.6rem;
`;

export const DescriptionContent = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-500);
  margin: 0 3.2rem;
`;

export const ButtonModalWrapper = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 1.6rem;
  margin: 2rem;
  @media screen and ${DESKTOP} {
    margin: 2.4rem 2rem;
  }
  &.informationFormButtonModalWrapper {
    flex-direction: column;
    margin-bottom: 0rem;
    row-gap: 1.6rem;
    @media screen and ${MOBILE} {
      margin: 0 1.6rem;
    }
    @media screen and ${TAB} {
      flex-direction: row-reverse;
    }
  }
`;

export const ButtonRecomputedRateModalWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 0 2rem 0 0;
`;

export const StyledTitle = styled.h2`
  margin: 1.5rem 0 0 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  color: ${({ theme }) => theme.vendor.colors.title};
  font-family: 'Ubuntu bold';
  @media screen and ${TAB} {
    font-size: 3.2rem;
    line-height: 4rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
`;

export const ModalRadioGroupContainer = styled.div`
  padding: 0 2.5%;
  max-height: 90vh;
`;

export const BubbleInfoLastNameWrapper = styled.div``;

export const BubbleInfoBirthNameWrapper = styled.div``;
