import { BackButton } from '@sweb-front/components';

import {
  BoldSpan,
  MainSectionWrapper,
  StyledParagraph,
  StyledTitle,
} from './Styles';
import { useEffect } from 'react';

const Cookies = () => {
  const style = { display: 'block' };
  const italic = { ...style, fontStyle: 'italic' };

  useEffect(() => {
    const cookies = document.getElementById('ot-sdk-cookie-policy');
    if (cookies) {
      cookies.style.display = 'initial';
    }
  }, []);

  return (
    <MainSectionWrapper>
      <BackButton />
      <StyledTitle>Politique cookies</StyledTitle>
      <StyledParagraph>
        Le Groupe BNP Paribas est particulièrement attentif à vous délivrer le
        meilleur service possible et nous souhaitons préserver la confiance que
        vous nous témoignez.
        <br />
        Dans ce cadre, nous avons adopté des principes forts afin d’assurer la
        protection de vos données.
      </StyledParagraph>
      <StyledParagraph>
        C&apos;est pourquoi nous souhaitons vous fournir des informations
        transparentes sur la manière dont nous plaçons, utilisons et stockons
        des cookies sur votre appareil lorsque vous consultez nos sites ou
        applications et vous présenter les options qui vous permettent de les
        gérer et les supprimer.
      </StyledParagraph>
      <StyledTitle>1. Qu&apos;est-ce qu&apos;un cookie ?</StyledTitle>
      <StyledParagraph>
        Les cookies sont de petits fichiers texte, image ou logiciel qui peuvent
        être déposés et/ou lus sur votre appareil lorsque vous accédez à notre
        Site et/ou à l’Application. Le mot &laquo; appareil &raquo;, lorsqu'il
        est utilisé dans la présente Politique Cookies, désigne notamment les
        ordinateurs, les téléphones intelligents, les tablettes et tout autre
        appareil utilisé pour accéder à Internet.
        <br />
        Les cookies peuvent être (i) de session, ce qui signifie qu’ils sont
        supprimés de votre terminal une fois la session et le navigateur fermés
        ou (ii) persistants, ce qui signifie qu’ils seront stockés sur votre
        terminal jusqu’à leur suppression.
      </StyledParagraph>
      <StyledParagraph>
        Les cookies peuvent avoir de nombreuses fonctions et permettent
        notamment de :
        <ul>
          <li>
            <BoldSpan>Vous authentifier et vous identifier</BoldSpan> sur notre
            Site et/ou l’Application afin de vous fournir les services que vous
            avez demandés;
          </li>
          <li>
            <BoldSpan>
              améliorer la sécurité du Site et/ou de l’Application
            </BoldSpan>{' '}
            y compris la prévention de l’usage frauduleux d’identifiants de
            connexion et la protection des données utilisateurs de l’accès par
            des tiers non autorisés;
          </li>
          <li>
            <BoldSpan>vous adresser des publicités personnalisées</BoldSpan>{' '}
            notamment basées sur vos historiques de navigation et préférences en
            ligne;
          </li>
          <li>
            <BoldSpan>
              Suivre votre manière d’utiliser notre Site et/ou Application afin
              de l’améliorer;
            </BoldSpan>
          </li>
          <li>
            <BoldSpan>améliorer votre expérience utilisateur</BoldSpan> en
            adaptant le contenu de notre Site ou de notre Application à vos
            centres d’intérêt et en vous proposant des publicités et contenus
            BNP Paribas plus pertinents sur notre Site et/ou Application ou des
            sites tiers;
          </li>
          <li>
            <BoldSpan>se souvenir des informations</BoldSpan> que vous nous avez
            fournies (par ex. pour remplir automatiquement les formulaires avec
            les informations que vous nous avez précédemment fournies afin de
            vous connecter plus rapidement) ;
          </li>
          <li>
            <BoldSpan>Enregistrer vos préférences</BoldSpan> et vos paramètres
            d&apos;utilisation de notre Site et/ou Application (par exemple
            langue, fuseau horaire, etc.).
          </li>
        </ul>
      </StyledParagraph>
      <StyledTitle>
        2. Quels types d’information peuvent être stockés dans un cookie ?
      </StyledTitle>
      <StyledParagraph>
        Les informations stockées par les cookies déposés sur votre appareil
        peuvent avoir trait aux éléments suivants, dans la limite de leur durée
        de conservation:
        <ul>
          <li>
            Les pages web que vous avez visitées en utilisant cet appareil;
          </li>
          <li>Les publicités sur lesquelles vous avez cliqué;</li>
          <li>Le type de navigateur que vous utilisez;</li>
          <li>Votre adresse IP;</li>
          <li>
            Et toute autre information que vous avez fournie sur notre
            Site/Application.
          </li>
        </ul>
        Les cookies peuvent contenir des données à caractère personnel couvertes
        par notre{' '}
        <a href="https://broadcast.mediahub.bnpparibas/mh/external/master/c4314d4f-8e56-4d3a-8715-6292ea8e79ec">
          Notice de Protection des Données.
        </a>
      </StyledParagraph>
      <StyledTitle>
        3. Quel type de cookies utilisons nous et à quelles fins ?
      </StyledTitle>
      <StyledParagraph>
        Les cookies que nous utilisons sur nos Sites et/ou Applications sont
        classés en différentes catégories
        <StyledParagraph>
          <BoldSpan style={style}>
            3.1 Cookies strictement nécessaires (obligatoires)
          </BoldSpan>
          Ces cookies sont indispensables afin de permettre au Site/à
          l’Application de fonctionner correctement. Ils peuvent comprendre, par
          exemple, des cookies qui collectent les identifiants de session et
          données d’identification, des cookies qui permettent de personnaliser
          l’interface d’un Site et/ou d’une Application (par exemple, pour le
          choix de la langue ou de la présentation d’un service) ou encore
          certains cookies de mesure d’audience. Cette catégorie comprend
          également les cookies qui nous permettent de nous conformer à nos
          obligations légales, notamment en assurant un environnement en ligne
          sûr (par exemple en détectant les échecs répétitifs de connexion afin
          d’empêcher des personnes non autorisées d’accéder à votre compte).
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={style}>
            3.2 Cookies de préférence (soumis à votre consentement)
          </BoldSpan>
          Ces cookies nous permettent ainsi qu’à nos partenaires, de
          personnaliser et proposer les contenus et fonctionnalités des Sites
          et/ou de l’Application (et notamment l’affichage de nos produits et
          services). Ils sont utilisés pour améliorer notre Site et/ou
          Application et votre expérience utilisateur.
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={style}>
            3.3 Cookies de mesure d’audience (soumis au consentement, sauf sous
            certaines conditions)
          </BoldSpan>
          Ces cookies nous permettent ainsi qu’à nos partenaires, de comprendre
          comment les utilisateurs arrivent sur notre Site et/ou Application, de
          mesurer le nombre de visiteurs sur notre Site et/ou Application mais
          également d’analyser la manière dont les visiteurs naviguent sur le
          Site/l’Application ou de reconstituer leur parcours. <br />
          Cela nous aide à améliorer la manière dont nos Sites et/ou
          Applications fonctionnent en nous assurant, par exemple, que les
          utilisateurs trouvent facilement ce qu’ils cherchent. <br />
          <br />
          Ces cookies peuvent être exemptés du consentement quand ils sont
          strictement nécessaires au fonctionnement du Site et/de l’Application
          conformément à l’article 3.1. <br />
          Dans le cas contraire, ils sont soumis au consentement conformément au
          présent article 3.3.
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={style}>
            3.4 Cookies publicitaires et cookies de suivi (soumis à votre
            consentement)
          </BoldSpan>
          Il existe différentes catégories de cookies permettant de vous
          proposer de la publicité. Les informations obtenues via des cookies
          publicitaires sont fréquemment partagées avec nos partenaires via
          l’utilisation de cookies tiers. Vous pouvez retrouver plus
          d’informations sur ces partenaires dans la section 4 de la présente
          Politique Cookies.
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={italic}>- Cookies publicité personnalisée</BoldSpan>
          Ces cookies sont utilisés afin de vous proposer des publicités
          personnalisées, plus adaptées à vos centres d’intérêt. Ils
          enregistrent votre visite sur notre Site et/ou Application, les pages
          que vous avez consultées ainsi que les liens que vous avez suivis.
          Nous utilisons ainsi que nos partenaires ces informations afin de
          personnaliser notre Site et/ou Application et les publicités qui y
          figurent, le cas échéant, en fonction de vos centres d’intérêt.
          <br />
          Ils peuvent également servir à afficher des publicités personnalisées
          sur nos produits sur d’autres sites, à mesurer l'efficacité d'une
          campagne publicitaire, à limiter la fréquence de diffusion
          (c'est-à-dire pour limiter le nombre de fois que vous voyez une
          publicité), l'affiliation publicitaire, la détection de la fraude au
          clic, les études de marché, l'amélioration des produits, le débogage.
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={italic}>
            - Cookies publicité non personnalisée
          </BoldSpan>
          Ces cookies nous permettent ainsi qu’à nos partenaires, d’afficher des
          publicités qui ne sont pas personnalisées en fonction de votre profil.
          Ces cookies peuvent servir exclusivement à mesurer la performance
          d’une campagne marketing.
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={italic}>- Cookies publicité géolocalisée</BoldSpan>
          Ces cookies nous permettent ainsi qu’à nos partenaires, de vous
          afficher des publicités personnalisées en fonction de votre
          localisation (publicités géolocalisées).
        </StyledParagraph>
        <StyledParagraph>
          <BoldSpan style={style}>
            3.5 Réseaux sociaux et cookies de partage de contenu (soumis à votre
            consentement)
          </BoldSpan>
          <StyledParagraph>
            <BoldSpan style={italic}>- Cookies de réseaux sociaux :</BoldSpan>
            Ces cookies nous permettent, ainsi qu’à nos partenaires, de partager
            des informations avec les réseaux sociaux utilisés sur nos Sites
            et/ou Applications. <br />
            L’affichage de ce type de contenu sur nos Sites et/ou Applications
            partage des informations à propos de votre navigation sur nos
            Sites/Applications avec les réseaux sociaux utilisés.
          </StyledParagraph>
          <StyledParagraph>
            <BoldSpan style={italic}>
              - Cookies de partage de contenu :
            </BoldSpan>
            Ces cookies nous permettent, ainsi qu’à nos partenaires, de vous
            proposer la visualisation sur notre Site/ Application de contenu
            hébergé sur un site externe. La visualisation de ce type de contenu
            sur nos Sites et/ou Applications implique de partager avec le site
            d’hébergement des informations à propos de votre navigation sur nos
            Sites et/ou Applications.
            <br />
            Vous trouverez dans la section 4 de la présente Politique Cookies de
            plus amples informations sur les partenaires ainsi que des
            explications sur la manière dont vous pouvez configurer vos
            préférences.
          </StyledParagraph>
        </StyledParagraph>
      </StyledParagraph>
      <StyledTitle>
        4. Dans ce cadre, qui place les cookies sur votre appareil (par exemple
        les sociétés du Groupe BNP Paribas ou un partenaire)?
      </StyledTitle>
      <StyledParagraph>
        Lorsque vous sélectionnez les types de cookies que vous autorisez sur
        votre appareil, ces cookies peuvent être directement déposés par nous ou
        par un de nos partenaires.
      </StyledParagraph>
      <StyledParagraph>
        D’un point de vue pratique, cela signifie que lorsque vous autorisez
        l’installation de certains cookies dits « tiers » sur votre appareil,
        nos partenaires pourront également accéder aux informations qu’ils
        contiennent (comme, par exemple, vos statistiques de navigation lorsque
        vous autorisez les cookies d'analyse tiers) dans le respect de notre
        Notice d’information des données personnelles et de celles de nos
        partenaires.
      </StyledParagraph>
      <StyledParagraph>
        <div id="ot-sdk-cookie-policy"></div>
      </StyledParagraph>
      <StyledTitle>5. Comment gérer les cookies?</StyledTitle>
      <StyledParagraph>
        Pour consulter les différentes catégories de cookies que nous utilisons
        sur le Site et/ou l’Application et paramétrer vos choix, vous pouvez
        consulter le module de gestion des cookies accessibles en cliquant sur
        ce bouton :
        <StyledParagraph className="cookies-button">
          <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings"
            style={{
              position: 'relative',
            }}
          >
            Gérer mes cookies
          </button>
        </StyledParagraph>
        <StyledParagraph>
          Vous pouvez modifier vos préférences à tout moment (retirer ou donner
          à nouveau votre consentement).
        </StyledParagraph>
        <StyledParagraph>
          Veuillez noter que l’utilisation des cookies strictement nécessaires
          au bon fonctionnement du Site/de l’Application ne requiert pas votre
          consentement. C’est pourquoi l’option « cookies strictement
          nécessaires » est pré cochée dans notre outil de gestion des cookies
          et n’est pas optionnelle.
        </StyledParagraph>
        <StyledParagraph>
          En refusant certains types de cookies (les cookies de préférence par
          exemple), nous ne serons pas en mesure d’optimiser votre expérience
          utilisateur sur notre Site et certaines parties pourraient ne pas
          fonctionner correctement.
        </StyledParagraph>
        <StyledParagraph>
          Par défaut, nous enregistrons vos choix en matière de cookies sur un
          appareil donné pour une durée maximale de 6 mois. Si vous changez
          d’avis sur les préférences que vous avez exprimées concernant les
          cookies, vous pouvez mettre à jour vos choix à tout moment, en
          cliquant sur le bouton ci-dessus « Gérer mes cookies ». Nous vous
          demanderons à nouveau de faire un choix tous les 6 mois.
        </StyledParagraph>
      </StyledParagraph>
    </MainSectionWrapper>
  );
};

export default Cookies;
