import { t } from 'i18next';
import {
  CustomerInformationCardWrapper,
  FooterButtonWrapper,
  ImgWrapper,
  InfoWrapper,
  MainInformationCardWrapper,
  MessageWrapper,
  SVGIconWrapper,
  SpanContent,
  TitleWrapper,
} from './styles';
import { trackEvent } from '@sweb-front/utils';
import { useAppSelector } from '@sweb-front/store';
import { WAY_TYPE_CB, WAY_TYPE_RIB } from 'src/libs/services/constants';

export type CustomerInformation = {
  id?: string;
  firstName: string;
  title: string;
  lastName: string;
  address: string;
  addressLine2?: string;
  zipCode: string;
  city: string;
  country: string;
  email: string;
  cellPhone: string;
  onButtonClick: () => void;
};

export interface ICustomerInformationnProps {
  props: CustomerInformation;
}

const trackEventEditButton = () => {
  trackEvent({ event: 'confirmExit' });
};

const CustomerInformationCard = ({ props }: ICustomerInformationnProps) => {
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const isLessThanThreeThousands = opportunity?.recap?.borrowedAmount <= 3000;
  const parameters = useAppSelector((state) => state.parameters.state);
  const { wayType } = parameters;

  return (
    <CustomerInformationCardWrapper id={props.id}>
      <MainInformationCardWrapper>
        <ImgWrapper>
          <base-icon icon="warning-Fill" />
        </ImgWrapper>
        <div className="content">
          <TitleWrapper>{props.title}</TitleWrapper>
          {isLessThanThreeThousands &&
            (wayType === WAY_TYPE_RIB || wayType === WAY_TYPE_CB) && (
              <MessageWrapper>
                {t(
                  'customerInformation.confirmationMessageLessThanThreeThousands'
                )}
              </MessageWrapper>
            )}
          {wayType === WAY_TYPE_RIB && !isLessThanThreeThousands && (
            <MessageWrapper>
              {t(
                'customerInformation.confirmationMessageMoreThanThreeThousands'
              )}
            </MessageWrapper>
          )}
          <InfoWrapper>
            {props.firstName && props.lastName && (
              <div>
                {props.firstName} {props.lastName}
              </div>
            )}
            {wayType === WAY_TYPE_RIB && !isLessThanThreeThousands && (
              <>
                {props.address && <div>{props.address}</div>}
                {props.addressLine2 && <div>{props.addressLine2}</div>}
                {props.zipCode && props.city && props.country && (
                  <div>
                    {props.zipCode} {props.city}{' '}
                    {props.country && <>{props.country}</>}
                  </div>
                )}
              </>
            )}
            <div>{props.email}</div>
            <div>{props.cellPhone}</div>
          </InfoWrapper>
        </div>
      </MainInformationCardWrapper>
      <FooterButtonWrapper
        onClick={(e) => {
          e.preventDefault();
          trackEventEditButton();
          props.onButtonClick();
        }}
      >
        <SpanContent>{t('customerInformation.modify')}</SpanContent>
        <SVGIconWrapper>
          <base-icon
            className="open-in-new"
            icon="open-in-new"
            viewBox="0 0 24 24"
          />
        </SVGIconWrapper>
      </FooterButtonWrapper>
    </CustomerInformationCardWrapper>
  );
};

export default CustomerInformationCard;
