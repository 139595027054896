import { IBasicInformation, IInsurance } from '.';
import {
  IAddress,
  IDistritutor,
  IEmail,
  IPerson,
  IPhone,
} from './PersonnalInformations';

export interface IOrderInformation {
  opportunityIdExt: string;
  testModeBo: boolean;
  requestId: string;
}

export interface IPersons {
  personalInformation: IBasicInformation;
  email: IEmail;
  cellPhone: IPhone;
  address: IAddress;
}

export interface IStoreInformation {
  distributorNb: string;
  companyCd: string;
  organizationId: string;
}
export interface IFinancialProduct {
  productCd?: string;
  productTypeCd?: string;
  productAlphaCd?: string;
  productCategoryCd?: string;
}

export interface IFinancialDetails {
  monthlyPaymentWithoutInsuranceAmt?: number;
  overdraftAmt?: number;
  term?: number;
  tncRt?: number;
  recomputedRateBo?: boolean;
}

export interface IUrlData {
  field: string;
  value: string;
}
export interface IGoods {
  goodCd?: string;
  goodLb?: string;
  goodPriceAmt?: number;
}
export interface IProject {
  loan: {
    financialDetails: IFinancialDetails;
    financialProduct: IFinancialProduct;
  };
  good: IGoods;
  oldProject?: IProject[];
  deliveryMode: string;
}

export interface ILoan {
  financialDetails: IFinancialDetails;
  financialProduct: IFinancialProduct;
  loanId: string;
  commitmentLevel: ICommitmentLevel;
  typeCd: string;
}

export interface IOffer {
  loans: ILoan[];
  offerId: string;
}

export interface IRecap {
  total: number;
  borrowedAmount: number;
  fixedTAEG: number;
  interests: number;
  products: IProducts[];
  periods: IPeriods[];
  summaryCashFund: ISummaryCashFund;
  hasTradeIn?: boolean;
  hasSummaryCashFund?: boolean;
  flexTradeIn?: IFlexTradeIn;
}

export interface IFlexTradeIn {
  good: IGood;
  loandId: string;
  paybackAmount: number;
  payforwardAmount: number;
}

export interface IGood {
  goodCd: string;
  goodDescription: string;
  goodDetails: [];
  goodImageUrl: string;
  goodLb: string;
  goodPriceAmt: number;
}

export interface IProducts {
  type?: string;
  logo?: string;
  name?: string;
  total?: number;
  nbOfMonths?: number;
  amountPerMonth?: number;
  totalToPay?: number;
  interestsRate?: number;
  interests?: number;
  supportAndService?: IProducts[];
  tradeIn?: IProducts;
  isChildren?: boolean;
  product?: IProducts;
}

export interface IPeriods {
  startMonth?: string;
  endMonth?: string;
  amountPerMonth?: number;
  goods?: IGoods[];
}

export interface ISummaryCashFund {
  goodCd: string;
  goodLb: string;
  logo: string;
  goodDescription: string;
  productCategoryCd: string;
  productAlphaCd: string;
  depositAmt: number;
  overdraftAmt: number;
  cashDetails: ISummaryCashDetail[];
}

export interface ISummaryCashDetail {
  description: string;
  productType: string;
  unitAmount: number;
  sequenceOrder: number;
}

export interface IRoutingContext {
  httpVerb?: string;
  contextUse: string;
  url: string;
}

export interface ICommitmentLevel {
  decisionCd: string;
  scoreLightCd: string;
  liveness: boolean;
}

export enum CHANELTYPE {
  ONLINE = 'ONLINE', // Web
  POS = 'POS', // Store
  TEL = 'TELESALES', // Telesales
  WM = 'WM', // Legacy type
  STORE_APP = 'STORE_APP', // Store App
}

export enum TradeInType {
  CLASSIC = 'CLASSIC', // No trade in
  TRADE_IN = 'TRADE_IN', // Trade In normal
  FLEX_WITH_TRADE_IN = 'FLEX_WITH_TRADE_IN', // Flexible with Trade In
  FLEX_WITHOUT_TRADE_IN = 'FLEX_WITHOUT_TRADE_IN', // Flexible without Trade In
}
export interface IOpportunity {
  opportunityIdExt?: string;
  distributionChannelCD?: CHANELTYPE;
  persons: IPerson[];
  bagType?: string;
  distritutor?: IDistritutor;
  project?: IProject;
  recap?: IRecap;
  isMobile?: boolean;
}

export interface PlanBResponse {
  data: {
    planB: boolean;
  };
}
