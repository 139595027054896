import { Title } from '@sweb-front/components';
import { DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media screen and ${DESKTOP} {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 5rem);
  }
`;

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 2.4rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  text-align: left;
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.4rem;

  span.expired-vatrib,
  span.expired-vatcb {
    margin-top: calc(-2.4rem + 16px);
  }

  span.error-vatrib,
  span.error-vatcb {
    margin-top: calc(-2.4rem + 16px);
  }
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  padding: 0;
  margin: 0;
  margin-top: 2.4rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: 'Ubuntu Bold';
`;
