import { Title } from '@sweb-front/components';
import { DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const SecondChanceErrorPageWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const IdentificationMessage = styled.span`
  margin-top: 1.6rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-700);
`;

export const WithoutIdentificationList = styled.ul`
  color: var(--neutrals-700);
  margin: 0;
`;

export const ListOne = styled.li`
  font-size: 1.4rem;
  line-height: 2rem;
  strong {
    font-family: 'Open Sans Bold';
  }
`;

export const ListTwo = styled.li`
  font-family: 'Open Sans Bold';
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const ListThree = styled.li`
  font-size: 1.4rem;
  line-height: 2rem;
  strong {
    font-family: 'Open Sans Bold';
  }
`;

export const BlockedIdentificationTitle = styled.span`
  margin-top: 1.6rem;
  color: var(--neutrals-700);
  font-family: 'Open Sans Bold';
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const BlockedIdentificationDescription = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-700);
`;

export const WithoutIdentificationTitle = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--neutrals-700);
  font-family: 'Open Sans Bold';
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(2.4rem - 1.6rem);
`;

export const NavigationButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  margin-bottom: 1.6rem;
`;

export const WithoutIdentificationWrapper = styled.div`
  margin-top: 1.6rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  @media screen and ${DESKTOP} {
    margin: auto;
  }
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  padding: 0;
  text-align: center;
  margin: 1.6rem 0 0 0;
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: 'Ubuntu Bold';
`;
