import { useUrlWebMarchand } from '@sweb-front/hooks';
import { ERRORDETAILS } from '@sweb-front/services';
import {
  selectNavigation,
  selectTheme,
  useAppSelector,
} from '@sweb-front/store';
import { trackEvent } from '@sweb-front/utils';
import {
  ERRORBUSINESSCODE,
  ERRORKEY,
  OPPORTUNITYSTATUS,
  STOREDVATTYPE,
} from '@vat/configuration';
import { abortOpportunity } from '@vat/services';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TOKENNAME = 'SolvaData';

const useErrorPage = () => {
  const { t } = useTranslation();
  const { getUrlError } = useUrlWebMarchand();
  const appNavigation = useAppSelector(selectNavigation);
  const themeName = useAppSelector(selectTheme);
  const mainContainer = document.getElementById('Main-container');

  const onBackToWebMerchant = useCallback(() => {
    const urlError = getUrlError();
    if (urlError) window.location.href = urlError;
  }, [appNavigation?.routingContexts]);

  useEffect(() => {
    // The user does not have to now the path of our application
    window.history.replaceState({}, document.title, '/');

    // This style is only for this page
    if (mainContainer) {
      mainContainer.style.width = '100%';
    }
  }, []);

  const isEndParcours = useMemo(() => {
    return appNavigation?.endParcours;
  }, [appNavigation?.endParcours]);

  const errorCode = useMemo(
    () => localStorage.getItem(ERRORKEY),
    [localStorage.getItem(ERRORKEY)]
  );
  const isExpired = errorCode === '401';

  const canCallingAbort = useMemo(() => {
    const isActiveStatus =
      errorCode !== null &&
      errorCode !== '401' &&
      [OPPORTUNITYSTATUS.ENCO, OPPORTUNITYSTATUS.PAPP].includes(
        appNavigation?.opportunityStatus
      );
    return isActiveStatus || (errorCode !== '401' && isActiveStatus);
  }, [errorCode, appNavigation?.opportunityStatus]);

  if (isExpired) {
    localStorage.removeItem(TOKENNAME);
  }

  // Display none cookies policy
  if (themeName === 'CETELEM') {
    const cookiesPolicy = document.querySelectorAll('.cookies-policy');
    if (cookiesPolicy?.length) {
      cookiesPolicy[0].classList.add('d-none');
    }
  }

  const executeAbort = async (
    isParcoursDone: boolean,
    token: string,
    withAbort: boolean,
    errorMessage?: string
  ) => {
    if (!isParcoursDone && withAbort) {
      await abortOpportunity(token, 'TECHNICAL', errorMessage ?? '')
        .then(() => {
          console.log(
            `Aborted opportunity successfully, \n\r Details: ${(
              errorMessage ?? ''
            )?.replace(', $OtherReasons', '')}`
          );
          localStorage.removeItem(ERRORDETAILS);
          localStorage.removeItem(ERRORBUSINESSCODE);
        })
        .catch((err) => console.error('Error while aborting opportunity', err));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedError = localStorage.getItem(ERRORDETAILS) ?? '';
    const businessCode: string = localStorage.getItem(ERRORBUSINESSCODE) ?? '';
    if (errorCode == '422' && businessCode === 'FINAL_STATUS_REACHED') {
      window.location.reload();
    } else {
      if (!token) {
        console.error("Couldn't abort opportunity, no token found");
        return;
      }
      executeAbort(isEndParcours, token, canCallingAbort, storedError);
    }
  }, []);

  const regexpVatcb = new RegExp('VATC');
  const isVatCB = regexpVatcb.test(localStorage.getItem(STOREDVATTYPE) ?? '');
  const text = isVatCB ? t('errorPage.bcNotUsed') : '';

  useEffect(() => {
    if (isExpired) {
      trackEvent({
        event: 'popinDisplay',
        modaleName: 'E-Commerce : Session expirée',
      });
    } else if (errorCode) {
      trackEvent({
        event: 'module_interaction',
        typeOfError: `Erreur ${errorCode}`,
        pageName: 'E-Commerce : Erreur Technique',
      });
    }
  }, [isExpired, errorCode]);

  const getErrorMessage = () => {
    if (isExpired) {
      return {
        title: t('errorPage.title.expired'),
        firstParagraph: t('errorPage.reDoTheLoan'),
        secondParagraph: text,
      };
    }

    return {
      title: t('errorPage.title.message'),
      firstParagraph: t('errorPage.problemEncountered'),
      secondParagraph: t('errorPage.tryLater'),
    };
  };

  /**
   * Get class name according to expiration or error case
   * @returns The class name to use
   */
  const getClassName = () => {
    if (isExpired) {
      return isVatCB ? 'expired-vatcb' : 'expired-vatrib';
    }
    return isVatCB ? 'error-vatcb' : 'error-vatrib';
  };

  /**
   * Get content of title
   * @returns The content to display
   */
  const getTitleContent = () => {
    return isExpired
      ? t('errorPage.title.expired')
      : t('errorPage.title.message');
  };

  /**
   * Get content of first paragraph
   * @returns The content to display
   */
  const getFirstParagraphContent = () => {
    return isExpired
      ? t('errorPage.reDoTheLoan')
      : t('errorPage.problemEncountered');
  };

  /**
   * Get content of second paragraph
   * @returns The content to display
   */
  const getSecondParagraphContent = () => {
    if (!isExpired) return isVatCB ? text : t('errorPage.tryLater');
    return text;
  };

  /**
   * Get content of third paragraph
   * @returns The content to display
   */
  const getThirdParagraphContent = () => {
    if (!isExpired && isVatCB) {
      return t('errorPage.tryLater');
    }
    return '';
  };

  return {
    onBackToWebMerchant,
    getErrorMessage,
    isEndParcours,
    getTitleContent,
    getFirstParagraphContent,
    getClassName,
    getSecondParagraphContent,
    getThirdParagraphContent,
  };
};

export default useErrorPage;
