import { LoaderWaitingPages } from '@sweb-front/components';
import {
  useAcceptationPolicy,
  useObResultTreatment,
  useOidcHelper,
} from '@sweb-front/hooks';
import {
  selectNavigation,
  setEndParcours,
  setLastAppUrl,
  setOpportunityStatus,
  setSEDecision,
  updateOpportunityStatus,
  updateParcoursNavigation,
  updateSteps,
  useAppDispatch,
  useAppSelector,
} from '@sweb-front/store';
import { MonextResponse } from '@sweb-front/types';
import { isStringEmpty, updateStatusOpp } from '@sweb-front/utils';
import {
  ERRORPAGE,
  INFORMATIONBANCAIRES,
  OPPORTUNITYSTATUS,
  PIECESJUSTIFICATIVES,
  REDIRECTION,
  REFUSEDREPONSE,
  SUCCESSREPONSE,
  THREEDS,
  WAITINGRESPONSE,
} from '@vat/configuration';
import {
  check3DSReturnCode,
  createECard,
  updateCardPayment,
} from '@vat/services';
import {
  ErrorContext,
  PageLoadingContext,
  useCloseOpportunity,
  useManagingExternalApp,
} from '@vat/utils';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type IWaitingPageProps = {
  waitingStep: string;
};

const WaitingPage = ({ waitingStep }: IWaitingPageProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { getAcceptationPolicy } = useAcceptationPolicy();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { updateAbortErrorMessage } = useCloseOpportunity();
  const { init3DS, finalizeLoanRequesting, waitingPageBeforeOnbo } =
    useManagingExternalApp();
  const { processObResult } = useObResultTreatment();
  const { processOidcReturn } = useOidcHelper();
  const appNavigation = useAppSelector(selectNavigation);
  const { monextResponse } = useAppSelector((state) => state.cardPayment.state);
  const { onboardingResponse } = useAppSelector(
    (state) => state.onboarding.state
  );
  const updateIsLoading = useContext(PageLoadingContext);
  const navigate = useNavigate();

  const goToPreviousPage = useCallback(() => {
    dispatch(
      updateSteps({
        externalAppName: 'monext',
        isExternalUrlAlreadyVisited: false,
      })
    );
    navigate(appNavigation.lastAppUrl, {
      replace: true,
    });
  }, [appNavigation]);

  const vatCbOnboWaitingPage = useCallback(() => {
    if (
      (!onboardingResponse || !onboardingResponse?.returnCode) &&
      onboardingResponse?.sign === onboardingResponse?.storedSign
    ) {
      init3DS(onboardingResponse, () => {
        dispatch(
          updateParcoursNavigation({
            name: PIECESJUSTIFICATIVES,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isErrorHappened: false,
            isSeDone: true,
            redirectionParam: 'redirectFromSignature',
          })
        );
      });
    } else if (onboardingResponse?.sign === onboardingResponse?.storedSign) {
      check3DSReturnCode(
        onboardingResponse?.returnCode,
        (response) => {
          if (response?.scoreLightCd === 'R') {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            dispatch(setEndParcours(true));
            updateStatusOpp(dispatch, OPPORTUNITYSTATUS.REFU);
            dispatch(setLastAppUrl(REFUSEDREPONSE));
            navigate(REFUSEDREPONSE, {
              state: {
                errorCode: onboardingResponse?.returnCode,
              },
            });
          } else if (response?.pspReturnCd === '00') {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            init3DS(monextResponse, () => {
              dispatch(
                updateSteps({
                  externalAppName: 'onbo',
                  isErrorHappened: false,
                  isSeDone: true,
                  redirectionParam: 'redirectFromSignature',
                })
              );
            });
          } else {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: false,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateAbortErrorMessage(
              ` Service onboarding/esign Ok mais la réponse "${
                response?.scoreLightCd ?? '-'
              }/${
                response?.pspReturnCd ?? '-'
              }" n'est pas contrôlée pour faire une action`
            );
            navigate(ERRORPAGE, { replace: true });
          }
        },
        () => {
          updateAbortErrorMessage(
            ` VATCB::esign Problème avec le service onboarding-esign avec comme retour Onbo ${Object.entries(
              onboardingResponse ?? {}
            )
              .map(([key, value]) => `${key}: ${value || '-'}`)
              .join('; ')}`
          );
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              loaded: true,
              actionPageDone: false,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          navigate(ERRORPAGE, {
            replace: true,
          });
        }
      );
    } else {
      updateAbortErrorMessage(
        ` Un souci sur l'appli Onbo (le service onboarding/esign n'est pas lancé à ce stade) "${Object.entries(
          onboardingResponse ?? {}
        )
          .map(([key, value]) => `${key}: ${value || '-'}`)
          .join(
            '; '
          )}", ou Peut être l'utilisateur a essayé de manuellement taper l'url de retour onbo`
      );
      dispatch(
        updateParcoursNavigation({
          name: PIECESJUSTIFICATIVES,
          loaded: true,
          actionPageDone: true,
          disabled: false,
          params: {
            hasError: true,
          },
        })
      );
      navigate(ERRORPAGE, { replace: true });
    }
  }, [onboardingResponse]);

  const checkScoreVATRIB = async () => {
    dispatch(
      updateSteps({
        externalAppName: 'onbo',
        isErrorHappened: false,
        isSeDone: true,
        redirectionParam: 'redirectFromSignature',
      })
    );
    dispatch(
      updateParcoursNavigation({
        name: PIECESJUSTIFICATIVES,
        loaded: true,
        actionPageDone: true,
        disabled: false,
      })
    );

    await getAcceptationPolicy(
      'RIB',
      'ONBO',
      (response) => {
        const scoreLight = response?.data?.scoreLightCd;
        dispatch(setEndParcours(true));
        if (scoreLight === 'V') {
          updateStatusOpp(dispatch, OPPORTUNITYSTATUS.APPR);
          dispatch(setLastAppUrl(SUCCESSREPONSE));
          navigate(SUCCESSREPONSE);
        } else if (scoreLight === 'R') {
          updateStatusOpp(dispatch, OPPORTUNITYSTATUS.REFU);
          dispatch(setLastAppUrl(REFUSEDREPONSE));
          navigate(REFUSEDREPONSE);
        } else {
          updateStatusOpp(dispatch, OPPORTUNITYSTATUS.PAPP);
          dispatch(setLastAppUrl(WAITINGRESPONSE));
          navigate(WAITINGRESPONSE);
        }
      },
      () => {
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isErrorHappened: true,
            isSeDone: false,
            redirectionParam: 'redirectFromSignature',
          })
        );
        updateAbortErrorMessage('getScore en erreur à la fin du parcours RIB');
        navigate(ERRORPAGE, {
          replace: true,
        });
      }
    );
  };

  const vatRIBOnboWaitingPage = useCallback(async () => {
    // In cas there is no errorCode from Onbo
    if (
      (!onboardingResponse || !onboardingResponse?.returnCode) &&
      onboardingResponse?.sign === onboardingResponse?.storedSign
    ) {
      const token = localStorage.getItem('token') ?? '';
      if (!isStringEmpty(token) && !!appNavigation.rightToEcard) {
        await createECard(token).catch(() => {
          updateAbortErrorMessage(` Service de création e-card KO`);
          dispatch(
            updateSteps({
              externalAppName: 'onbo',
              isErrorHappened: true,
              isSeDone: false,
              redirectionParam: 'redirectFromSignature',
            })
          );
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              loaded: true,
              actionPageDone: true,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          navigate(ERRORPAGE, {
            replace: true,
          });
        });
      }
      await checkScoreVATRIB(); // acceptation policy
    }
    // In cas there is errorCode from Onbo
    else if (onboardingResponse?.sign === onboardingResponse?.storedSign) {
      check3DSReturnCode(
        // esignature
        onboardingResponse?.returnCode,
        async (response) => {
          if (response?.scoreLightCd === 'R') {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
              })
            );
            dispatch(setEndParcours(true));
            updateStatusOpp(dispatch, OPPORTUNITYSTATUS.REFU);
            dispatch(setLastAppUrl(REFUSEDREPONSE));
            navigate(REFUSEDREPONSE, {
              state: {
                errorCode: onboardingResponse?.returnCode,
              },
            });
          } else {
            dispatch(
              updateParcoursNavigation({
                name: PIECESJUSTIFICATIVES,
                loaded: true,
                actionPageDone: true,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            updateAbortErrorMessage(
              ` Service onboarding/esign Ok mais la réponse "${
                response?.scoreLightCd ?? '-'
              }/${
                response?.pspReturnCd ?? '-'
              }" n'est pas contrôlée pour faire une action`
            );
            navigate(ERRORPAGE, { replace: true });
          }
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: PIECESJUSTIFICATIVES,
              loaded: true,
              actionPageDone: true,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateAbortErrorMessage(
            ` Problème avec le service onboarding/esign avec comme retour Onbo "${Object.entries(
              onboardingResponse ?? {}
            )
              .map(([key, value]) => `${key}: ${value ?? '-'}`)
              .join('; ')}"`
          );
          navigate(ERRORPAGE, { replace: true });
        }
      );
    } else {
      updateAbortErrorMessage(
        ` Un souci sur l'appli Onbo (le service onboarding/esign n'est pas lancé à ce stade) "${Object.entries(
          onboardingResponse ?? {}
        )
          .map(([key, value]) => `${key}: ${value || '-'}`)
          .join(
            '; '
          )}", ou Peut être l'utilisateur a essayé de manuellement taper l'url de retour onbo`
      );
      dispatch(
        updateParcoursNavigation({
          name: PIECESJUSTIFICATIVES,
          loaded: true,
          actionPageDone: true,
          disabled: false,
          params: {
            hasError: true,
          },
        })
      );
      navigate(ERRORPAGE, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (waitingStep === 'attente-cb') {
      dispatch(
        updateSteps({
          externalAppName: 'monext',
          params: monextResponse as unknown as Record<string, unknown>,
        })
      );

      updateCardPayment(
        monextResponse?.cardId,
        monextResponse?.cardRef,
        monextResponse?.returnCode,
        monextResponse?.returnValue,
        (response) => {
          dispatch(setSEDecision(response));
          const seDecision = response;
          if (seDecision?.pspReturnCd === '12') {
            dispatch(
              updateParcoursNavigation({
                name: INFORMATIONBANCAIRES,
                actionPageDone: false,
                disabled: false,
              })
            );
            goToPreviousPage();
          } else if (seDecision?.pspReturnCd === 'ERROR') {
            dispatch(
              updateParcoursNavigation({
                name: INFORMATIONBANCAIRES,
                actionPageDone: false,
                disabled: false,
                params: {
                  hasError: true,
                },
              })
            );
            navigate(ERRORPAGE, { replace: true });
            dispatch(
              updateSteps({
                externalAppName: 'monext',
                waitingPagePath:
                  window.location.pathname + window.location.search,
                isErrorHappened: true,
              })
            );
          } else {
            dispatch(
              updateParcoursNavigation({
                name: INFORMATIONBANCAIRES,
                loaded: true,
                actionPageDone: true,
                locked: true,
                disabled: false,
              })
            );
            waitingPageBeforeOnbo();
          }
        },
        () => {
          dispatch(
            updateParcoursNavigation({
              name: INFORMATIONBANCAIRES,
              loaded: true,
              actionPageDone: false,
              disabled: false,
              params: {
                hasError: true,
              },
            })
          );
          updateAbortErrorMessage(
            isStringEmpty(monextResponse?.cardRef)
              ? "les paramètres CardId et/ou CardRef venant de monext sont absents ou n'ont pas de valeurs."
              : `Un problème au niveau du service bank-card est détecté ${Object.entries(
                  monextResponse ?? ({} as MonextResponse)
                )
                  .map(([key, value]) => `${key}: "${value}"`)
                  .join('; ')}`
          );
          navigate(ERRORPAGE, { replace: true });
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              waitingPagePath:
                window.location.pathname + window.location.search,
              isErrorHappened: true,
            })
          );
        }
      );
    } else if (waitingStep === 'attente-onboarding') {
      vatCbOnboWaitingPage();
    } else if (waitingStep === 'attente-onboarding-rib') {
      vatRIBOnboWaitingPage();
    } else if (waitingStep === 'attente-3ds') {
      dispatch(
        updateSteps({
          externalAppName: '3ds',
          params: monextResponse,
          isErrorHappened: false,
          isSeDone: true,
        })
      );
      dispatch(
        updateParcoursNavigation({
          name: THREEDS,
          actionPageDone: true,
        })
      );
      if (
        monextResponse &&
        (monextResponse.threeDsRef || monextResponse.returnCode !== '00')
      ) {
        finalizeLoanRequesting(monextResponse);
      } else {
        navigate(REDIRECTION);
      }
    } else if (waitingStep === 'attente-openbanking') {
      processObResult();
    } else if (waitingStep === 'attente-authentification') {
      processOidcReturn(opportunity);
    }

    return () => {
      updateIsLoading(false);
    };
  }, [waitingStep]);

  return <LoaderWaitingPages />;
};

export default WaitingPage;
