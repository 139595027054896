import { ImgWrapper } from './styles';

export const SecondChanceImage = () => {
  return (
    <ImgWrapper>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Action/warning">
          <path
            id="Icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.0434 35H6.87401C5.59188 35 4.44635 34.331 3.80816 33.2096C3.16996 32.0881 3.1757 30.7525 3.82308 29.6368L17.1207 6.71405C17.7428 5.64002 18.8505 5 20.0833 5H20.089C21.3241 5.00231 22.4329 5.64697 23.0527 6.72447L36.1724 29.521C36.8301 30.6634 36.8313 32.0279 36.1781 33.1725C35.525 34.3172 34.3531 35 33.0434 35ZM20.0844 7.14344C19.8571 7.14344 19.297 7.20709 18.9561 7.79619L5.65846 30.7189C5.4002 31.1633 5.3979 31.6957 5.65272 32.1425C5.90639 32.5892 6.36323 32.8566 6.87401 32.8566H33.0423C33.5829 32.8566 34.0661 32.5742 34.3359 32.1031C34.6056 31.6309 34.6045 31.0684 34.3336 30.5974L21.215 7.79966C20.8753 7.20825 20.3128 7.14344 20.0867 7.14344H20.0844ZM18.5518 24.4635C18.5518 25.2528 19.1865 25.8928 19.9693 25.8928C20.7522 25.8928 21.3869 25.2528 21.3869 24.4635V15.3828C21.3869 14.5935 20.7522 13.9535 19.9693 13.9535C19.1865 13.9535 18.5518 14.5935 18.5518 15.3828V24.4635ZM18.3101 28.5408C18.3101 29.513 19.094 30.3035 20.0582 30.3035C21.0224 30.3035 21.8075 29.513 21.8064 28.5408C21.8064 27.5686 21.0224 26.7782 20.0582 26.7782C19.094 26.7782 18.3101 27.5686 18.3101 28.5408Z"
            fill="#268038"
          />
        </g>
      </svg>
    </ImgWrapper>
  );
};

export default SecondChanceImage;
