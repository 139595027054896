import { HelperMessageWrapper } from '@sweb-front/styles';
import { MOBILE } from '@sweb-front/utils';
import styled from 'styled-components';

export const CustomerInformationCardWrapper = styled(HelperMessageWrapper)`
  display: flex;
  justify-content: left;
  flex-direction: column;
  vertical-align: middle;
  position: relative;
  background: #fef4e5;
  color: #292c2e;
  border: 0.1rem solid var(--color-border-accent-orange, #e78a08);
  padding: 2.4rem 1.4rem;
  & .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: center;
  }
`;

export const MainInformationCardWrapper = styled.div`
  display: flex;
`;

export const ImgWrapper = styled.div`
  padding: 0;
  margin-right: 0.8rem;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
    --color-icon: #e78a08;
  }
`;

export const SVGIconWrapper = styled.div`
  margin: 0;
  padding: 0;

  & base-icon {
    --color-icon: var(-neutrals-700);
    width: 1.8rem;
    height: 1.8rem;
  }
`;

export const TitleWrapper = styled.div`
  font-family: 'Open Sans Bold';
  font-size: 1.8rem;
  line-height: 2.6rem;
  text-align: left;
`;

export const MessageWrapper = styled.div`
  font-family: 'Open Sans';
  font-size: 1.4rem;
  line-height: 2rem;
  letter-spacing: -0.01em;
  margin-top: 0.8rem;
  margin-bottom: 2.4rem;
  text-align: left;
  color: var(--neutrals-500);
`;

export const InfoWrapper = styled.div`
  & div {
    font-family: 'Open Sans Bold';
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--neutrals-500);
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 1.4rem;
  align-self: end;
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1.3rem;
  margin-top: 1.6rem;
  @media screen and ${MOBILE} {
    justify-content: end;
  }
`;

export const SpanContent = styled.span`
  margin-right: 0.8rem;
`;

export const StyledButton = styled.button`
  width: 10.4rem;
  height: 3.6rem;
  padding: 0.8rem 0.4rem 0.8rem 0.8rem;
  border-radius: 0.4rem;
  background: transparent;
  border: 0.1rem solid black;
  font-family: 'Open Sans Bold';
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  & base-icon {
    width: 2.4rem;
    height: 2.4rem;
    --icon-width: 2.4rem;
    --icon-height: 2.4rem;
  }
`;
