import { Title } from '@sweb-front/components';
import { DESKTOP } from '@sweb-front/utils';
import styled from 'styled-components';

export const PingDownErrorPageWrapper = styled.div`
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media screen and ${DESKTOP} {
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 5rem);
  }
`;

export const TechnicalMessage = styled.span`
  margin-top: 1.6rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--neutrals-700);
`;

export const RetryMessage = styled.span`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--neutrals-700);
`;

export const AppleMessage = styled.span`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--neutrals-500);
`;

export const CenterWrapper = styled.div`
  margin-right: 2.4rem;
`;

export const NavigationButton = styled.div`
  margin-top: 4rem;
  margin-bottom: 1.6rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  padding: 0;
  margin: 2.4rem 0 0 0;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: 'Ubuntu Bold';
`;
