import {
  AppleMessage,
  CenterWrapper,
  ContentWrapper,
  PingDownErrorPageWrapper,
  NavigationButton,
  RetryMessage,
  TechnicalMessage,
  TitleCmp,
} from './styles';
import usePingDownErrorPage from './usePingDownErrorPage';
import { t } from 'i18next';
import { CHANELTYPE } from '@sweb-front/types';
import { Button } from '@vat/components';
import { useAppSelector } from '@sweb-front/store';
import ErrorDeskIcon from 'src/components/Icons/ErrorDeskIcon';

const PingDownErrorPage = () => {
  const { onBackToWebMerchant } = usePingDownErrorPage();

  const opportunity = useAppSelector((state) => state.opportunity.state);

  return (
    <PingDownErrorPageWrapper data-testid="PingDownErrorPageWrapper">
      <ErrorDeskIcon />
      <CenterWrapper>
        <ContentWrapper>
          <TitleCmp>
            {t('orderSummary.flexibleTradeIn.errors.pingDown.title')}
          </TitleCmp>
          <TechnicalMessage id="technical">
            {t('orderSummary.flexibleTradeIn.errors.pingDown.technical')}
          </TechnicalMessage>
          <RetryMessage id="retry">
            {t('orderSummary.flexibleTradeIn.errors.pingDown.retry')}
          </RetryMessage>
          {opportunity?.distributionChannelCD === CHANELTYPE.POS && (
            <AppleMessage>
              {t('orderSummary.flexibleTradeIn.errors.pingDown.apple')}
            </AppleMessage>
          )}
        </ContentWrapper>
        {opportunity?.distributionChannelCD !== CHANELTYPE.POS && (
          <NavigationButton id="navigationButton">
            <Button
              id="button-leave"
              level="primary"
              onClick={onBackToWebMerchant}
              isBold
            >
              {t('basicInformations.backToWebSite')}
            </Button>
          </NavigationButton>
        )}
      </CenterWrapper>
    </PingDownErrorPageWrapper>
  );
};

export default PingDownErrorPage;
