import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import configurationReducer, {
  clear as clearConfiguration,
  selectConfiguration,
  setAvailableToRiba,
  setCardName,
  setChainNb,
  setConfiguration,
  setInstallmentId,
  setIsInTestMode,
  setOrganizationId,
  setProject,
  setRequestId,
  setReturnUrl,
  setVendorId,
  updateConfiguration,
} from './configuration/configurationSlice';
export * from './configuration/configurationTypes';

import opportunityReducer, {
  IOpportunityState,
  clear as clearOpportunity,
  fetchOpportunity,
  setDistributor,
  setOffers,
  setPerson,
  setPersonalInformation,
  setAddress,
  setEmail,
  setPhone,
  setHousing,
  setFamilySituation,
  setIncomes,
  setOutgoings,
  setProfession,
  isStoreMarchand,
  setOpportunityStatus,
} from './opportunity/opportunitySlice';
import { IState } from './opportunity/opportunityTypes';
import * as optionTypes from './option/optionTypes';
import personReducer, {
  clear as clearPerson,
  selectPerson,
  setCustomerAccountStore,
  setOcrKo,
  setPersonAccountData,
  setPersonAddressInfo,
  setPersonBankInfo,
  setPersonContactInfo,
  setPersonIdentity,
  updatePerson,
} from './person/personSlice';
import * as personTypes from './person/personTypes';

import assuranceReducer, {
  clear as clearAssurance,
  setAssurance,
} from './assurance/assuranceSlice';
import optionReducer, {
  clear as clearOption,
  selectOption,
  setOption,
} from './option/optionSlice';
import parametersReducer, {
  IParameterState,
  clear as clearParameters,
  fetchWay,
  setParams,
  selectTheme,
  selectDisplays,
  parametersSlice,
  selectParams,
} from './parameters/parametersSlice';
import stepsReducer, {
  addStep,
  clear as clearSteps,
  selectSteps,
  setSteps,
  IStep,
} from './steps/stepSlice';

import cardPaymentReducer, {
  clear as clearCardPayment,
  selectCardPaymentState,
  setCardPayment,
  setSEDecision,
  setMonexResponse,
} from './cardPayment/cardPaymentSlice';

import onboardingSliceReducer, {
  clear as clearOnboardingResponse,
  setOnboardingResponse,
} from './onboarding/onboardingSlice';
import referentielsReducer, {
  IReferentielState,
  clear as clearReferentiels,
  setCities,
  setCountries,
  setDepartements,
  setNationalities,
} from './referentiels/referentielsSlice';

import navigationReducer, {
  clear as clearNavigation,
  setRightToEcard,
  setLastAppUrl,
  setLastStep,
  updateSteps,
  setEndParcours,
  selectNavigation,
  getActiveStep,
  isSeDoneThroughSteps,
  setRandomSign,
  setTypeWayCd,
  getStepByExternalAppName,
  setRoutingContexts,
  updateOpportunityStatus,
  INavigationState,
  IAppStep,
  setIsFilledWithAutoComplete,
  setAutocompletefieldValue,
  setWebAEventPage,
  setVatType,
  isOpenBankingResultOk,
  setOpenbankingChoice,
  updateParcoursNavigation,
  getNestedStateRequest,
} from './navigation/navigationSlice';
import { INavigationPageState, TPageName } from './navigation/navigationType';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  configuration: configurationReducer,
  option: optionReducer,
  opportunity: opportunityReducer,
  parameters: parametersReducer,
  steps: stepsReducer,
  assurance: assuranceReducer,
  referentiel: referentielsReducer,
  cardPayment: cardPaymentReducer,
  navigation: navigationReducer,
  onboarding: onboardingSliceReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

setupListeners(store.dispatch);

const clearStore = () => {
  store.dispatch(clearPerson());
  store.dispatch(clearConfiguration());
  store.dispatch(clearOption());
  store.dispatch(clearOpportunity());
  store.dispatch(clearReferentiels());
  store.dispatch(clearParameters());
  store.dispatch(clearSteps());
  store.dispatch(clearAssurance());
  store.dispatch(clearOnboardingResponse());
};

export const clearNavigationStore = () => {
  store.dispatch(clearCardPayment());
  store.dispatch(clearNavigation());
};

export {
  clearStore,
  clearCardPayment,
  /* Person */
  personTypes,
  setPersonIdentity,
  setPersonBankInfo,
  setPersonAddressInfo,
  setPersonContactInfo,
  setPersonAccountData,
  setOcrKo,
  setCustomerAccountStore,
  updatePerson,
  selectPerson,
  personReducer as person,
  setFamilySituation,
  setIncomes,
  setOutgoings,
  setProfession,
  /* Configuration */
  // configurationTypes,
  setConfiguration,
  updateConfiguration,
  setCardName,
  setHousing,
  setOrganizationId,
  setChainNb,
  setProject,
  setVendorId,
  setInstallmentId,
  setReturnUrl,
  setIsInTestMode,
  setRequestId,
  setAvailableToRiba,
  selectConfiguration,
  configurationReducer as configuration,
  /* Option */
  optionTypes,
  setOption,
  selectOption,
  optionReducer as option,
  opportunityReducer as opportunity,
  parametersReducer as parameters,
  fetchWay,
  setParams,
  selectDisplays,
  parametersSlice,
  /* Steps */
  stepsReducer as steps,
  addStep,
  setSteps,
  selectSteps,
  selectTheme,
  selectParams,
  /** Opportunity */
  setAddress,
  setEmail,
  setPhone,
  fetchOpportunity,
  setPerson,
  setDistributor,
  setOffers,
  setPersonalInformation,
  isStoreMarchand,
  setOpportunityStatus,
  /** Assurance */
  assuranceReducer as assurance,
  setAssurance,
  /** Referentiels */
  referentielsReducer as referentiel,
  setCountries,
  setCities,
  setDepartements,
  setNationalities,
  selectCardPaymentState,
  setCardPayment,
  setSEDecision,
  setMonexResponse,
  cardPaymentReducer as cardPayment,
  /** Navigation */
  setRightToEcard,
  setLastAppUrl,
  setLastStep,
  updateSteps,
  setEndParcours,
  setRandomSign,
  setTypeWayCd,
  setRoutingContexts,
  setWebAEventPage,
  getActiveStep,
  isSeDoneThroughSteps,
  selectNavigation,
  navigationReducer as navigation,
  getStepByExternalAppName,
  getNestedStateRequest,
  clearNavigation,
  updateOpportunityStatus,
  setIsFilledWithAutoComplete,
  setAutocompletefieldValue,
  setVatType,
  setOpenbankingChoice,
  isOpenBankingResultOk,
  updateParcoursNavigation,
  setOnboardingResponse,
  clearOnboardingResponse,
};

export type {
  IState,
  IAppStep,
  IReferentielState,
  IStep,
  IOpportunityState,
  IParameterState,
  INavigationState,
  TPageName,
  INavigationPageState,
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
